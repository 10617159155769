import React, {useState, forwardRef, useImperativeHandle, useRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import * as Yup from 'yup'
import clsx from 'clsx'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

import * as authHelper from '../../../modules/auth/core/AuthHelpers'
import {swal} from '../../../../_metronic/helpers/swal'

export const AddModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({
    image_file_ru: Yup.mixed().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    image_file_uz: Yup.mixed(),
    image_file_en: Yup.mixed(),
    name_ru: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    description_ru: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    name_uz: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    description_uz: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    name_en: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    description_en: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    url: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    position: Yup.number().integer(),
    is_active: Yup.number().integer(),
  })

  let initialValues = {
    image_file_ru: '',
    image_file_uz: '',
    image_file_en: '',
    name_ru: '',
    name_uz: '',
    name_en: '',
    description_ru: '',
    description_uz: '',
    description_en: '',
    url: '',
    position: 0,
    is_active: 1,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.post(
          '/feedback/',
          {
            author_id: user.id,
            url: values.url,
            position: values.position || null,
            image_file_ru: values.image_file_ru || null,
            image_file_uz: values.image_file_uz || null,
            image_file_en: values.image_file_en || null,
            name_ru: values.name_ru,
            name_uz: values.name_uz,
            name_en: values.name_en,
            description_ru: values.description_ru,
            description_uz: values.description_uz,
            description_en: values.description_en,
            // @ts-ignore:
            is_active: parseInt(values.is_active, 10) || 0,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (err: any) {
        console.log('error', err)
        let msg = (((err || {}).response || {}).data || {}).message

        setStatus(intl.formatMessage({id: 'VALIDATION.API_ERROR'}, {text: msg || err.message}))
        return
      }
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  return (
    <Modal className='es-modal' size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.URL'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('url')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.url && formik.errors.url},
                {
                  'is-valid': formik.touched.url && !formik.errors.url,
                }
              )}
              value={formik.values.url}
            />
          </div>
          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.IMAGE_RU'})}</label>
            <input
              type='file'
              onChange={(event) => {
                // @ts-ignore
                formik.setFieldValue('image_file_ru', event.currentTarget.files[0])
              }}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.image_file_ru && formik.errors.image_file_ru},
                {
                  'is-valid': formik.touched.image_file_ru && !formik.errors.image_file_ru,
                }
              )}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_RU'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('name_ru')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.name_ru && formik.errors.name_ru},
                {
                  'is-valid': formik.touched.name_ru && !formik.errors.name_ru,
                }
              )}
              value={formik.values.name_ru}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.DESCRIPTION_RU'})}</label>
            <textarea
              {...formik.getFieldProps('description_ru')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.description_ru && formik.errors.description_ru},
                {
                  'is-valid': formik.touched.description_ru && !formik.errors.description_ru,
                }
              )}
              value={formik.values.description_ru}
              placeholder='Leave a description here'
            ></textarea>
          </div>
          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.IMAGE_UZ'})}</label>
            <input
              type='file'
              onChange={(event) => {
                // @ts-ignore
                formik.setFieldValue('image_file_uz', event.currentTarget.files[0])
              }}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.image_file_uz && formik.errors.image_file_uz},
                {
                  'is-valid': formik.touched.image_file_uz && !formik.errors.image_file_uz,
                }
              )}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_UZ'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('name_uz')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.name_uz && formik.errors.name_uz},
                {
                  'is-valid': formik.touched.name_uz && !formik.errors.name_uz,
                }
              )}
              value={formik.values.name_uz}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.DESCRIPTION_UZ'})}</label>
            <textarea
              {...formik.getFieldProps('description_uz')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.description_uz && formik.errors.description_uz},
                {
                  'is-valid': formik.touched.description_uz && !formik.errors.description_uz,
                }
              )}
              value={formik.values.description_uz}
              placeholder='Leave a description here'
            ></textarea>
          </div>
          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.IMAGE_EN'})}</label>
            <input
              type='file'
              onChange={(event) => {
                // @ts-ignore
                formik.setFieldValue('image_file_en', event.currentTarget.files[0])
              }}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.image_file_en && formik.errors.image_file_en},
                {
                  'is-valid': formik.touched.image_file_en && !formik.errors.image_file_en,
                }
              )}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_EN'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('name_en')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.name_en && formik.errors.name_en},
                {
                  'is-valid': formik.touched.name_en && !formik.errors.name_en,
                }
              )}
              value={formik.values.name_en}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'ITEM.DESCRIPTION_EN'})}</label>
            <textarea
              {...formik.getFieldProps('description_en')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.description_en && formik.errors.description_en},
                {
                  'is-valid': formik.touched.description_en && !formik.errors.description_en,
                }
              )}
              value={formik.values.description_en}
              placeholder='Leave a description here'
            ></textarea>
          </div>
          <div className='col-12 my-5'>
            <hr className='f-hr' />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.POSITION'})}</label>
            <input
              type='number'
              {...formik.getFieldProps('position')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.position && formik.errors.position},
                {
                  'is-valid': formik.touched.position && !formik.errors.position,
                }
              )}
              value={formik.values.position}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.ACTIVE'})}</label>

            <select className='form-select' {...formik.getFieldProps('is_active')}>
              <option key={'is_active-1'} selected={formik.values.is_active == 1} value={1}>
                {intl.formatMessage({id: 'STATUS.1'})}
              </option>
              <option key={'is_active-0'} selected={formik.values.is_active == 0} value={0}>
                {intl.formatMessage({id: 'STATUS.0'})}
              </option>
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-primary'
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          {intl.formatMessage({id: 'COMMON.SAVE'})}
        </button>
      </Modal.Footer>
    </Modal>
  )
})
