import React, {useState, forwardRef, useImperativeHandle, useRef, ReactNode} from 'react'
import Modal from 'react-bootstrap/Modal'

import 'react-datepicker/dist/react-datepicker.css'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {getMediaUrl} from '../../../../../_metronic/helpers/general'

export const ClientAddresses = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  return (
    <Modal size='lg' show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({id: 'COMMON.CLIENT_ADDRESSES'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.addresses.map((x: any, idx: string) => {
          return (
            <div
              className='border-bottom py-1 c-pointer'
              onClick={() => {
                props.setFormik('address', x.commentary)
                props.setFormik('latitude', x.latitude)
                props.setFormik('longitude', x.longitude)
                // props.setFormik('terminalId', x.terminalId)
                // props.setFormik('deliveryPrice', x.deliveryPrice)
                // props.setFormik('deliveryDistance', x.deliveryDistance)
                props.setShow(false)
              }}
            >
              {x.address}
            </div>
            // <button
            //   className='btn d-block border-bottom rounded-0'
            //   onClick={() => {
            //     props.setFormik('commentary', x.address)
            //     props.setFormik('latitude', x.latitude)
            //     props.setFormik('longitude', x.longitude)
            //     props.setFormik('terminalId', x.terminalId)
            //     props.setFormik('deliveryPrice', x.deliveryPrice)
            //     props.setFormik('deliveryDistance', x.deliveryDistance)
            //     props.setShow(false)
            //   }}
            //   key={'client-address-index-' + idx}
            // >
            //   {x.address}
            // </button>
          )
        })}
      </Modal.Body>
    </Modal>
  )
})
