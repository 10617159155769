import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import moment from 'moment'
import * as Yup from 'yup'
import clsx from 'clsx'

// @ts-ignore:
import Dropzone from 'react-dropzone'

import {useFormik} from 'formik'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {swal} from '../../../../_metronic/helpers/swal'

import {DeleteModal} from './DeleteModal'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'

export function ItemModal(props: any) {
  const intl = useIntl()

  let modalRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const deleteModalRef = useRef()

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    key: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    value: Yup.string().when('file', {
      // @ts-ignore
      is: (file) => !file || !(file || {}).name,
      then: Yup.string()
        .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
        .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    }),
    file: Yup.mixed(),
  })

  const initialValues = {
    title: '',
    key: '',
    value: '',
    file: '',
  }

  if (!!props.item && !!props.item.id) {
    initialValues.title = props.item.title
    initialValues.key = props.item.key
    initialValues.value = props.item.value
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.put(
          `/settings/${props.item.id}`,
          {
            author_id: user.id,
            id: props.item.id,
            key: values.key,
            title: values.title,
            value: values.value,
            file: values.file || null,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'errsuccessor')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
      }
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  useEffect(() => {
    const element = modalRef.current

    // @ts-ignore: Object is possibly 'null'.
    element.addEventListener('hidden.bs.modal', (event) => {
      formik.resetForm()
    })

    return () => {
      // @ts-ignore: Object is possibly 'null'.
      element.removeEventListener('hidden.bs.modal', (event) => {
        formik.resetForm()
      })
    }
  }, [])

  return (
    <>
      <div className='modal fade es-modal' tabIndex={-1} id='itemInfoModal' ref={modalRef}>
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <form onSubmit={formik.handleSubmit}>
                {formik.status ? (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className='mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'COMMON.NAME'})}</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('title')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.title && formik.errors.title},
                      {
                        'is-valid': formik.touched.title && !formik.errors.title,
                      }
                    )}
                    value={formik.values.title}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'COMMON.KEY'})}</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('key')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.key && formik.errors.key},
                      {
                        'is-valid': formik.touched.key && !formik.errors.key,
                      }
                    )}
                    value={formik.values.key}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'COMMON.VALUE'})}</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('value')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.value && formik.errors.value},
                      {
                        'is-valid': formik.touched.value && !formik.errors.value,
                      }
                    )}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'COMMON.IMAGE'})}</label>
                  <Dropzone
                    multiple={false}
                    onDrop={(files) => formik.setFieldValue('file', files[0])}
                  >
                    {({getRootProps, getInputProps}) => (
                      <div
                        {...getRootProps({
                          className: clsx(
                            'form-control c-dropzone',
                            {
                              'is-invalid': formik.touched.file && formik.errors.file,
                            },
                            {
                              'is-valid': formik.touched.file && !formik.errors.file,
                            }
                          ),
                        })}
                      >
                        <input {...getInputProps()} />
                        <p className='m-0'>
                          {!!formik.values.file
                            ? intl.formatMessage({id: 'COMMON.PICTURE_SELECTED'})
                            : intl.formatMessage({id: 'COMMON.SELECT_PICTURE'})}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-danger btn-sm me-auto'
                data-bs-toggle='modal'
                // @ts-ignore
                onClick={() => deleteModalRef.current.showModal()}
              >
                {intl.formatMessage({id: 'COMMON.DELETE'})}
              </button>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                {intl.formatMessage({id: 'COMMON.CANCEL'})}
              </button>
              <button
                type='button'
                className='btn btn-success btn-sm'
                onClick={() => {
                  formik.handleSubmit()
                }}
                disabled={loading}
                data-kt-indicator={loading == true ? 'on' : 'off'}
              >
                <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
    </>
  )
}
