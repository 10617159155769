import React, {useEffect, useRef} from 'react'
import axios from 'axios'
import moment from 'moment'
import clsx from 'clsx'

import {useIntl} from 'react-intl'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import {
  FullscreenControl,
  Map,
  Placemark,
  SearchControl,
  YMaps,
  ZoomControl,
  Polygon,
  useYMaps,
} from '@pbe/react-yandex-maps'
import {swal} from '../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
// @ts-ignore:
import Select from 'react-select'
import Loader from '../../components/Loader'
import FooterCopyright from '../../modules/FooterCopyright'
import {KTSVG} from '../../../_metronic/helpers'
import Timer from '../../components/Timer/Timer'

export function CouriersMapIndex() {
  const intl = useIntl()
  const {classes} = useLayout()

  const mapRef = useRef<any>(null)
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  const [data, setData] = React.useState<any>(null)
  const [orders, setOrders] = React.useState<any>([])
  const [times, setTimes] = React.useState([])
  const [search, setSearch] = React.useState<any>('')
  const [filteredData, setFilteredData] = React.useState<any>([])

  const [active, setActive] = React.useState<any>(false)
  const [dataDate, setDataDate] = React.useState('null')
  const [branches, setBranches] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  async function getData() {
    try {
      const res = await axios.get(`/courier/`)
      setData(res.data || [])
      setDataDate(moment().format('HH:mm:ss'))
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      // setError(err)
    }
  }

  async function getAdditionalData() {
    try {
      const branchesRes = await axios.get('/terminals/all/')
      setBranches(branchesRes.data.data || [])
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  async function getOrdersById(ordersList: any) {
    setOrders([])
    ordersList.map(async (orderL: any) => {
      try {
        const res = await axios.get(`/orders/${orderL.id}`)
        setOrders((prev: any) => [...prev, res.data])
      } catch (err: any) {
        setIsLoading(false)
        if (err.response.status == 404) {
          swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
      }
    })
  }

  React.useEffect(() => {
    setIsLoading(true)
    getData()
    getAdditionalData()
  }, [])

  const refreshData = () => {
    setIsLoading(true)
    getData()
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      getData()
    }, 60 * 1000)

    return () => clearInterval(intervalId)
  }, [])

  function filterByName(name: React.ChangeEvent<HTMLInputElement>) {
    setFilteredData([])
    data.map((d: any) => {
      if (d.name.toLocaleLowerCase().includes(name.target.value.toLocaleLowerCase())) {
        setFilteredData((prev: any) => [...prev, d])
      }
    })
    if (name.target.value.length == 0) {
      setFilteredData([])
    }
    setSearch(name.target.value)
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  const getOrderStatusLog = (status: string, index: number) => {
    let ordersLog = orders[index]?.order_logs?.map((x: any) => x)
    let filteredOrdersLog = ordersLog?.filter((orderLog: any) => orderLog?.event == status)
    if (!!filteredOrdersLog) {
      return filteredOrdersLog[filteredOrdersLog?.length - 1]?.date
    }

    return ''
  }

  const getBaloonOrders = (orders: any = []) => {
    let text: any = []
    orders.map((order: any, index: number) => {
      // let orderTimer = calculateTimeLeft(getOrderStatusLog('TAKEOFF', index))

      // text.push(`<li>#${order.id} - ${order.commentary} ${times.length > 0 ? times[0] : ''}</li>`)
      text.push(`<li>#${order.id} - ${order.commentary}</li>`)
    })

    return text.join('')
  }

  function calculateTimeLeft(targetTime: string) {
    const targetDate = new Date(targetTime)
    const difference = new Date().getTime() - targetDate.getTime()

    if (difference <= 0) {
      return `00:00:00`
    }

    const seconds =
      Math.floor((difference / 1000) % 60) > 9
        ? Math.floor((difference / 1000) % 60)
        : '0' + Math.floor((difference / 1000) % 60)
    const minutes =
      Math.floor((difference / (1000 * 60)) % 60) > 9
        ? Math.floor((difference / (1000 * 60)) % 60)
        : '0' + Math.floor((difference / (1000 * 60)) % 60)
    const hours =
      Math.floor((difference / (1000 * 60 * 60)) % 24) > 9
        ? Math.floor((difference / (1000 * 60 * 60)) % 24)
        : '0' + Math.floor((difference / (1000 * 60 * 60)) % 24)
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))

    return `${hours}:${minutes}:${seconds}`
  }

  useEffect(() => {
    const timerInterval = setInterval(() => {
      let newTimes: any = times.map((x) => x)
      orders.map((ord: any, index: number) => {
        newTimes[index] = calculateTimeLeft(getOrderStatusLog('TAKEOFF', index))
      })
      setTimes(newTimes)
    }, 1000)

    return () => clearInterval(timerInterval)
  }, [orders])

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.COURIERS_MAP'}) + (dataDate ? ' | ' + dataDate : '')}
      </PageTitle>
      <div className='d-flex flex-column flex-column-fluid'>
        <div
          className='row flex-column-fluid overflow-hidden'
          style={{height: windowSize.current[1] - 160}}
        >
          <div className='col-md-12 d-flex flex-column flex-column-fluid h-100 position-relative'>
            <div
              className='d-flex align-items-center position-absolute top-0 lef-0'
              style={{zIndex: 5}}
            >
              <div
                className={`card card-stretch shadow mb-5 overflow-auto ${!active && 'd-none'}`}
                style={{height: windowSize.current[1] - 168}}
              >
                <div className='card-body'>
                  <div className='col-12'>
                    <div className='d-flex align-items-center position-relative input-group-sm my-1 me-3'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen021.svg'
                        className='svg-icon-1 position-absolute ms-4'
                      />
                      <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-100 ps-14'
                        placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                        onChange={filterByName}
                        value={search}
                      />

                      {search.length > 0 && (
                        <button
                          className='btn link btn-sm p-0 ms-3'
                          data-bs-toggle='modal'
                          onClick={(e) => {
                            setSearch('')
                            setFilteredData([])
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen040.svg'
                            className='svg-icon-2hx svg-icon-danger'
                          />
                        </button>
                      )}
                    </div>
                  </div>
                  {search.length == 0 && (
                    <div className='col-12'>
                      {(data || [])
                        .sort((a: any, b: any) => b.is_online - a.is_online)
                        .map((x: any) => (
                          <div
                            // @ts-ignore:
                            key={'courierL-' + x.id}
                            className='border-bottom'
                            onClick={() => {
                              if (!x.latitude || !x.longitude) return

                              mapRef.current.setCenter([x.latitude, x.longitude])
                              mapRef.current.setZoom(15)
                            }}
                          >
                            <button className='btn py-2 px-0 text-start w-100'>
                              <i
                                className={`fa-solid fa-circle ${
                                  x.is_online && x.orders.length > 0
                                    ? 'text-primary'
                                    : x.is_online
                                    ? 'text-success'
                                    : 'text-danger'
                                }`}
                              ></i>
                              {x.name}
                            </button>
                          </div>
                        ))}
                    </div>
                  )}

                  {search.length > 0 && (
                    <div className='col-12'>
                      {(filteredData || [])
                        .sort((a: any, b: any) => b.is_online - a.is_online)
                        .map((x: any) => (
                          <div
                            // @ts-ignore:
                            key={'courierL-' + x.id}
                            className='border-bottom'
                            onClick={() => {
                              if (!x.latitude || !x.longitude) return

                              mapRef.current.setCenter([x.latitude, x.longitude])
                              mapRef.current.setZoom(15)
                            }}
                          >
                            <button className='btn py-2 px-0 text-start w-100'>
                              <i
                                className={`fa-solid fa-circle ${
                                  x.is_online && x.orders.length > 0
                                    ? 'text-primary'
                                    : x.is_online
                                    ? 'text-success'
                                    : 'text-danger'
                                }`}
                              ></i>
                              {x.name}
                            </button>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{height: windowSize.current[1] - 168}}
                className='d-flex align-items-center ms-3'
              >
                <button
                  type='button'
                  className='btn bg-warning px-4 pe-2 ps-3 pt-1'
                  onClick={() => setActive(!active)}
                >
                  {active ? (
                    <i className='bi bi-arrow-left m-0 text-white fs-2'></i>
                  ) : (
                    <i className='bi bi-arrow-right m-0 text-white fs-2'></i>
                  )}
                </button>
              </div>
            </div>

            <div className='card card-stretch shadow mb-2 flex-column-fluid'>
              <div className='card-body'>
                <YMaps
                  query={{
                    apikey: process.env.REACT_APP_YMAPS_KEY,
                    lang: 'ru_RU',
                    load: 'package.full',
                  }}
                >
                  <Map
                    // instanceRef={(ref) => {
                    //   if (ref) {
                    //     ref.events.add('boundschange', (e) => {
                    //       let latitude = e.originalEvent.map.getCenter()[0]
                    //       let longitude = e.originalEvent.map.getCenter()[1]

                    //       console.log('latitude', latitude)
                    //     })
                    //   }
                    // }}
                    instanceRef={mapRef}
                    defaultState={{
                      center: [41.311151, 69.279737],
                      zoom: 12,
                      controls: [],
                    }}
                    options={{
                      suppressMapOpenBlock: true,
                    }}
                    style={{width: '100%', height: '100%'}}
                  >
                    <FullscreenControl />
                    <SearchControl
                      options={{
                        float: 'left',
                      }}
                    />
                    <ZoomControl />
                    {(data || [])
                      .filter((x: any) => !!x.latitude && !!x.longitude)
                      .map((x: any) => (
                        <Placemark
                          onClick={() => {
                            setTimes([])
                            getOrdersById(x.orders)
                          }}
                          key={'courierPin-' + x.id}
                          geometry={[x.latitude, x.longitude]}
                          options={{
                            preset:
                              x.is_online && x.orders.length > 0
                                ? 'islands#blueStretchyIcon'
                                : x.is_online
                                ? 'islands#greenStretchyIcon'
                                : 'islands#redStretchyIcon',
                          }}
                          properties={{
                            iconContent: x.name,
                            hintContent: x.name,
                            balloonContent: `<h2>${x.name}</h2>
                            <span><b>Дата посл. локации:</b> ${moment(x.last_location).format(
                              'DD/MM/YYYY HH:mm'
                            )}</span><br/>
                            <span><b>Количество заказов:</b> ${x.orders.length}</span>
                            ${x.orders.length > 0 ? '<h6>Заказы:</h6>' : ''}
                           
                            <ul>
                            ${getBaloonOrders(x.orders)}
                            </ul>
                            
                            
                            `,
                          }}
                        />
                      ))}

                    {(branches || []).map((x: any) => (
                      <Placemark
                        key={'branchPin-' + x.id}
                        geometry={[x.latitude, x.longitude]}
                        options={{
                          preset: 'islands#blueStretchyIcon',
                        }}
                        properties={{
                          iconContent: `Филиал: ${x.name_ru}`,
                          hintContent: `Филиал: ${x.name_ru}`,
                        }}
                      />
                    ))}
                  </Map>
                </YMaps>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
