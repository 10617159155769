import React, {useState, useCallback, useMemo} from 'react'
import axios from 'axios'
import {useIntl} from 'react-intl'
import ImageViewer from 'react-simple-image-viewer'
import {convertToPrice, getMediaUrl} from '../../../../../_metronic/helpers/general'

import {useParams, useSearchParams} from 'react-router-dom'
import {buildQueryData} from '../../../../../_metronic/helpers/general'
import {PageTitle, useLayout} from '../../../../../_metronic/layout/core'
import FooterCopyright from '../../../../modules/FooterCopyright'
import Loader from '../../../../components/Loader'
import {swal} from '../../../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../../../_metronic/helpers/constants'
import {useRoleContext} from '../../../../context/getAccessRoleUser'
import {ItemModal} from './components/ItemModal'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ModofierModal} from './components/ModofierModal'

export function GroupsProductsItemIndex() {
  const intl = useIntl()
  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()
  let [searchParams, setSearchParams] = useSearchParams()

  let {org_id, parent_group, product_id} = useParams()

  // LOCAL STATE
  const [data, setData] = React.useState<any>({id: '', name_ru: ''})
  const [group, setGroup] = React.useState({id: '', name_ru: ''})
  const [allGroups, setAllGroups] = React.useState([])
  const [products, setProducts] = useState([])
  const [modifiers, setModifiers] = useState<any>([])
  const [organization, setOrganization] = React.useState({id: '', name: ''})
  const [isLoading, setIsLoading] = useState(true)
  const [choosenItem, setChoosenItem] = React.useState(null)

  const itemModalRef = React.useRef()
  const modifierModalRef = React.useRef<any>()

  // ImageViewer
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  // @ts-ignore
  //   let viewerImages = data.map((x) => getMediaUrl('products', x.image))
  let viewerImages = []
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])
  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  // End of ImageViewer

  async function getData() {
    try {
      const res = await axios.get(`products/${product_id}`)
      setData(res.data || {})

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getProducts() {
    try {
      const res = await axios.get(`products/?is_page_all=true`)
      setProducts(res.data.data || {})

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  async function getProductModifiers() {
    try {
      const res = await axios.get(`products/modifier/${product_id}?organization_id=${org_id}`)
      res.data.map((r: any) => {
        r.productModifierChild.map((child: any) => {
          setModifiers((prev: any) => [...prev, child])
        })
      })

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getGroup(parentGroup: string) {
    if (!parentGroup) return
    try {
      const res = await axios.get(`groups/${parentGroup}`)
      setGroup(res.data)
    } catch (err: any) {
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getAllGroups() {
    // @ts-ignore
    if (!group.region_id) return

    try {
      // @ts-ignore
      let postData = {region_id: parseInt(group.region_id, 10)}

      let queryData = buildQueryData(postData)

      const res = await axios.get(`groups/?${queryData}`)

      setAllGroups(res.data.data || [])
    } catch (err: any) {
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getOrganization(orgId: string) {
    if (!orgId) return
    try {
      const res = await axios.get(`organization/${orgId}`)
      setOrganization(res.data)
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    getData()
  }, [searchParams])

  React.useEffect(() => {
    // @ts-ignore
    getGroup(parent_group)
    // @ts-ignore
    getOrganization(org_id)
    getProducts()
    getProductModifiers()
  }, [])
  React.useEffect(() => {
    getAllGroups()
  }, [group])

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className='c-pointer'
          onClick={(e) => {
            e.stopPropagation()
            itemClick(value)
            modifierModalRef.current.showModal()
          }}
        >
          <td>{i + 1}</td>
          <td>{value.modifier?.name_ru}</td>
          <td>{value.max_amount}</td>
          <td>{value.min_amount}</td>
          <td className='text-nowrap'>
            {convertToPrice(value?.price || 0) + ' ' + intl.formatMessage({id: 'COMMON.SUM'})}
          </td>
          <td className='text-center'>
            {value.modifier.is_active == 1 ? (
              <i className='fa-regular fa-square-check text-success'></i>
            ) : (
              <i className='far fa-window-close text-danger'></i>
            )}
          </td>
        </tr>
      )
    }

    return content
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id == choosenItem.id) || null)
    }
  }, [data])

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.MENU'}),
        path: '/groups',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: (organization || {}).name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'}),
        path: `/groups/menu/${(organization || {}).id}`,
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: (group || {}).name_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'}),
        path: `/groups/menu/${(organization || {}).id}/products/${(group || {}).id}`,
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{data.name_ru}</PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='card card-stretch shadow mb-2'>
              <div className='card-body main-overflow-x'>
                <div className='table-responsive'>
                  <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-1 gs-1'>
                    <tbody>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'COMMON.NAME'})}
                        </th>
                        <td>{data.name_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                      </tr>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'COMMON.DESCRIPTION'})}
                        </th>
                        <td>
                          {data.description_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'COMMON.URL'})}
                        </th>
                        <td>{data.url || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                      </tr>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'COMMON.PRICE'})}
                        </th>
                        <td>
                          {convertToPrice(data?.price || 0) +
                            ' ' +
                            intl.formatMessage({id: 'COMMON.SUM'})}
                        </td>
                      </tr>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'COMMON.POSITION'})}
                        </th>
                        <td>{data.position || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                      </tr>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'ITEM.SEO_TITLE_RU'})}
                        </th>
                        <td>
                          {data.seo_title_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'ITEM.SEO_DESCRIPTION_RU'})}
                        </th>
                        <td>
                          {data.seo_description_ru ||
                            intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'ITEM.SEO_KEYWORD_RU'})}
                        </th>
                        <td>
                          {data.seo_keywords_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'COMMON.AVAILABLE'})}
                        </th>
                        <td>
                          {data.is_active == 1
                            ? intl.formatMessage({id: 'COMMON.YES'})
                            : intl.formatMessage({id: 'COMMON.NO'})}
                        </td>
                      </tr>
                      <tr>
                        <th className=' w-200px'>
                          {intl.formatMessage({id: 'COMMON.AVAILABLE_ONLINE'})}
                        </th>
                        <td>
                          {data.is_active_online == 1
                            ? intl.formatMessage({id: 'COMMON.YES'})
                            : intl.formatMessage({id: 'COMMON.NO'})}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card card-stretch shadow mb-2'>
              <div className='card-header'>
                <h2 className='card-title'>{intl.formatMessage({id: 'COMMON.IMAGE'})}</h2>
              </div>
              {data.image && (
                <div className='card-body'>
                  <img className='img-fluid' src={getMediaUrl('products', data.image)} alt='' />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-between w-100'>
              <h2 className='card-title'>{intl.formatMessage({id: 'COMMON.RECOMMENDED'})}</h2>
              <button
                className='btn btn-success btn-sm me-3'
                data-bs-toggle='modal' // @ts-ignore
                onClick={() => itemModalRef.current.showModal()}
              >
                {intl.formatMessage({id: 'COMMON.ADD'})}
              </button>
            </div>
          </div>

          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-1 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.IMAGE'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.URL'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.PRICE'})}</th>
                  </tr>
                </thead>
                <tbody>{getDataContent(data.recommend)}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <h2 className='card-title'>{intl.formatMessage({id: 'COMMON.MODIFIERS'})}</h2>
          </div>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-1 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.MAX_AMOUNT'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.MIN_AMOUNT'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.PRICE'})}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{getDataContent(modifiers)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ModofierModal ref={modifierModalRef} choosenItem={choosenItem} org_id={org_id} />

      <ItemModal
        ref={itemModalRef}
        products={products}
        id={product_id}
        refreshData={refreshData}
        recommend={data?.recommend}
      />
      {isViewerOpen && (
        <ImageViewer
          // @ts-ignore
          src={viewerImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
}
