import React, {useState, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import axios from 'axios'
import {useIntl} from 'react-intl'
import ImageViewer from 'react-simple-image-viewer'
import ReactApexChart from 'react-apexcharts'
import {convertToPrice, getMediaUrl} from '../../../../../_metronic/helpers/general'

import {useParams, useSearchParams} from 'react-router-dom'
import {buildQueryData} from '../../../../../_metronic/helpers/general'
import {PageTitle, useLayout} from '../../../../../_metronic/layout/core'
import FooterCopyright from '../../../../modules/FooterCopyright'
import Loader from '../../../../components/Loader'
import {swal} from '../../../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../../../_metronic/helpers/constants'
import {useRoleContext} from '../../../../context/getAccessRoleUser'
import {ItemModal} from './components/ItemModal'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ModofierModal} from './components/ModofierModal'

export function GroupsProductsTestItemIndex() {
  const intl = useIntl()
  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()
  let [searchParams, setSearchParams] = useSearchParams()

  let {org_id, parent_group, product_id} = useParams()

  // LOCAL STATE
  const [data, setData] = React.useState<any>({id: '', name_ru: ''})
  const [group, setGroup] = React.useState({id: '', name_ru: ''})
  const [allGroups, setAllGroups] = React.useState([])
  const [products, setProducts] = useState([])
  const [modifiers, setModifiers] = useState<any>([])
  const [organization, setOrganization] = React.useState({id: '', name: ''})
  const [isLoading, setIsLoading] = useState(true)
  const [choosenItem, setChoosenItem] = React.useState(null)

  const itemModalRef = React.useRef()
  const modifierModalRef = React.useRef<any>()

  const [selectedItems, setSelectedItems] = React.useState<any>([])

  const [apexOrdersByDay, setApexOrdersByDay] = useState<any>({
    series: [
      {
        name: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
        data: [44, 55, 41, 67, 41, 67, 33],
      },
    ],

    options: {
      colors: ['#198754'],
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {show: !1},
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        width: 1,
      },
      xaxis: {
        show: false,
        labels: {
          show: false,
        },
        categories: ['April 1', 'April 2', 'April 3', 'April 4', 'April 5', 'April 6', 'April 7'],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      // fill: {
      //   type: 'gradient',
      //   gradient: {
      //     shade: 'light',
      //     type: 'horizontal',
      //     shadeIntensity: 0.25,
      //     gradientToColors: undefined,
      //     inverseColors: true,
      //     opacityFrom: 0.85,
      //     opacityTo: 0.85,
      //     stops: [50, 0, 100],
      //   },
      // },
    },
  })

  // ImageViewer
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  // @ts-ignore
  //   let viewerImages = data.map((x) => getMediaUrl('products', x.image))
  let viewerImages = []
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])
  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  // End of ImageViewer

  async function getData() {
    try {
      const res = await axios.get(`products/${product_id}`)
      setData(res.data || {})
      console.log(res.data.recommend)
      changeSelectedItems(res.data)

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getProducts() {
    try {
      const res = await axios.get(`products/?is_page_all=true`)
      setProducts(res.data.data || {})

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getProductModifiers() {
    try {
      const res = await axios.get(`products/modifier/${product_id}?organization_id=${org_id}`)
      res.data.map((r: any) => {
        r.productModifierChild.map((child: any) => {
          setModifiers((prev: any) => [...prev, child])
        })
      })

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getGroup(parentGroup: string) {
    if (!parentGroup) return
    try {
      const res = await axios.get(`groups/${parentGroup}`)
      setGroup(res.data)
    } catch (err: any) {
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getAllGroups() {
    // @ts-ignore
    if (!group.region_id) return

    try {
      // @ts-ignore
      let postData = {region_id: parseInt(group.region_id, 10)}

      let queryData = buildQueryData(postData)

      const res = await axios.get(`groups/?${queryData}`)

      setAllGroups(res.data.data || [])
    } catch (err: any) {
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  async function getOrganization(orgId: string) {
    if (!orgId) return
    try {
      const res = await axios.get(`organization/${orgId}`)
      setOrganization(res.data)
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    getData()
  }, [searchParams])

  React.useEffect(() => {
    // @ts-ignore
    getGroup(parent_group)
    // @ts-ignore
    getOrganization(org_id)
    getProducts()
    getProductModifiers()
  }, [])
  React.useEffect(() => {
    getAllGroups()
  }, [group])

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-data' + i}
          className='c-pointer'
          onClick={(e) => {
            e.stopPropagation()
            itemClick(value)
            modifierModalRef.current.showModal()
          }}
        >
          <td>{i + 1}</td>
          <td>{value.modifier?.name_ru}</td>
          <td className='text-center'>{value.max_amount}</td>
          <td className='text-center'>{value.min_amount}</td>
          <td className='text-center'>
            <span
              className={clsx(
                'badge badge-light-success ',
                {'badge-light-success': value.modifier.is_active},
                {
                  'badge-light-danger': !value.modifier.is_active,
                }
              )}
            >
              {value.modifier.is_active
                ? intl.formatMessage({id: 'COMMON.ACTIVE'})
                : intl.formatMessage({id: 'COMMON.NOT_ACTIVE'})}
            </span>
          </td>
          <td className='text-nowrap'>
            {convertToPrice(value?.price || 0) + ' ' + intl.formatMessage({id: 'COMMON.SUM'})}
          </td>
        </tr>
      )
    }

    return content
  }

  const getRecommendedContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr key={'list-recommended-' + i} className='c-pointer'>
          <td>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input widget-9-check'
                type='checkbox'
                value='1'
                checked={selectedItems.includes(value.id)}
                onClick={(e) => {
                  // e.stopPropagation()
                  changeSelectedItems([value])
                }}
              />
            </div>
          </td>
          <td>{i + 1}</td>
          <td>
            <img className='row-img' src={getMediaUrl('products', value.image)} alt='' />
          </td>
          <td>{value.name_ru}</td>
          <td>{value.url}</td>
          <td className='text-nowrap'>
            {convertToPrice(value?.price || 0) + ' ' + intl.formatMessage({id: 'COMMON.SUM'})}
          </td>
        </tr>
      )
    }

    return content
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id == choosenItem.id) || null)
    }
  }, [data])

  const changeSelectedItems = (array: any, allSelect: boolean = false) => {
    let items: any = []
    items = selectedItems.map((s: any) => s)

    if (allSelect) {
      if (items.length >= array.length) {
        setSelectedItems([])
        return
      }
      let getAllId: any = []
      array.map((item: any) => {
        getAllId.push(item.id)
      })
      setSelectedItems(getAllId)
      return
    }
    array.map((item: any) => {
      let newSelectedItem = items.filter((selectItem: any) => selectItem == item.id)
      let newSelectedItems = items.filter((selectItem: any) => selectItem != item.id)

      if (newSelectedItem.length > 0) {
        items = newSelectedItems
        return
      }
      items.push(item.id)
    })
    setSelectedItems(items)
  }

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.MENU'}),
        path: '/groups',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: (organization || {}).name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'}),
        path: `/groups/menu/${(organization || {}).id}`,
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: (group || {}).name_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'}),
        path: `/groups/menu/${(organization || {}).id}/products/${(group || {}).id}`,
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{data.name_ru}</PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='form d-flex flex-column flex-lg-row w-100 mb-5'>
          <div className='d-flex flex-column gap-5 gap-lg-5 w-100 w-lg-350px mb-5 me-lg-5'>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'COMMON.IMAGE'})}</h2>
                </div>
              </div>
              <div className='card-body text-center pt-0'>
                <div
                  className='image-input image-input-empty image-input-outline image-input-placeholder mb-3'
                  data-kt-image-input='true'
                >
                  <div
                    className='image-input-wrapper w-150px h-150px'
                    style={{
                      backgroundImage: 'url(/metronic8/demo8/assets/media//stock/ecommerce/78.png)',
                    }}
                  />
                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    aria-label='Change avatar'
                    data-bs-original-title='Change avatar'
                    data-kt-initialized={1}
                  >
                    <i className='fa-solid fa-pencil fs-8'></i>
                    <input type='file' name='avatar' accept='.png, .jpg, .jpeg' />
                    <input type='hidden' name='avatar_remove' />
                  </label>
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    aria-label='Cancel avatar'
                    data-bs-original-title='Cancel avatar'
                    data-kt-initialized={1}
                  >
                    <i className='ki-duotone ki-cross fs-2'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>
                  </span>
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    aria-label='Remove avatar'
                    data-bs-original-title='Remove avatar'
                    data-kt-initialized={1}
                  >
                    <i className='ki-duotone ki-cross fs-2'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>{' '}
                  </span>
                </div>
                <div className='text-muted fs-7'>
                  Установите миниатюру продукта. Принимаются только файлы изображений *.png, *.jpg и
                  *.jpeg.
                </div>
              </div>
            </div>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'COMMON.STATUS'})}</h2>
                </div>
                <div className='card-toolbar'>
                  <div
                    className='rounded-circle bg-success w-15px h-15px'
                    id='kt_ecommerce_add_product_status'
                  />
                </div>
              </div>
              <div className='card-body pt-0'>
                <select
                  className='form-select mb-2 select2-hidden-accessible'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Select an option'
                  id='kt_ecommerce_add_product_status_select'
                  data-select2-id='select2-data-kt_ecommerce_add_product_status_select'
                  tabIndex={-1}
                  aria-hidden='true'
                  data-kt-initialized={1}
                >
                  <option />
                  <option
                    value='published'
                    // selected=""
                    data-select2-id='select2-data-11-ovty'
                  >
                    Published
                  </option>
                  <option value='draft'>Draft</option>
                  <option value='scheduled'>Scheduled</option>
                  <option value='inactive'>Inactive</option>
                </select>
                <div className='text-muted fs-7'>Set the product status.</div>
                <div className='d-none mt-10'>
                  <label
                    htmlFor='kt_ecommerce_add_product_status_datepicker'
                    className='form-label'
                  >
                    Select publishing date and time
                  </label>
                  <input
                    className='form-control flatpickr-input'
                    id='kt_ecommerce_add_product_status_datepicker'
                    placeholder='Pick date & time'
                    type='text'
                    // readOnly='readonly'
                  />
                </div>
              </div>
            </div>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>Product Details</h2>
                </div>
              </div>
              <div className='card-body pt-0'>
                <label className='form-label'>Categories</label>
                <select
                  className='form-select mb-2 select2-hidden-accessible'
                  data-control='select2'
                  data-placeholder='Select an option'
                  data-allow-clear='true'
                  // multiple=''
                  data-select2-id='select2-data-12-3vwi'
                  tabIndex={-1}
                  aria-hidden='true'
                  data-kt-initialized={1}
                >
                  <option />
                  <option value='Computers'>Computers</option>
                  <option value='Watches'>Watches</option>
                  <option value='Headphones'>Headphones</option>
                  <option value='Footwear'>Footwear</option>
                  <option value='Cameras'>Cameras</option>
                  <option value='Shirts'>Shirts</option>
                  <option value='Household'>Household</option>
                  <option value='Handbags'>Handbags</option>
                  <option value='Wines'>Wines</option>
                  <option value='Sandals'>Sandals</option>
                </select>
                <div className='text-muted fs-7 mb-7'>Add product to a category.</div>
                {/* <a
                      href='/metronic8/demo8/apps/ecommerce/catalog/add-category.html'
                      className='btn btn-light-primary btn-sm mb-10'
                    >
                      <i className='ki-duotone ki-plus fs-2' /> Create new category
                    </a> */}
                {/* <label className='form-label d-block'>Tags</label> */}
                {/* <tags className='tagify  form-control mb-2' tabIndex={-1}>
                      <tag
                        title='new'
                        contentEditable='false'
                        spellCheck='false'
                        tabIndex={-1}
                        className='tagify__tag tagify--noAnim'
                        value='new'
                      >
                        <x
                          title=''
                          tabIndex={-1}
                          className='tagify__tag__removeBtn'
                          role='button'
                          aria-label='remove tag'
                        />
                        <div>
                          <span className='tagify__tag-text'>new</span>
                        </div>
                      </tag>
                      <tag
                        title='trending'
                        contentEditable='false'
                        spellCheck='false'
                        tabIndex={-1}
                        className='tagify__tag tagify--noAnim'
                        value='trending'
                      >
                        <x
                          title=''
                          tabIndex={-1}
                          className='tagify__tag__removeBtn'
                          role='button'
                          aria-label='remove tag'
                        />
                        <div>
                          <span className='tagify__tag-text'>trending</span>
                        </div>
                      </tag>
                      <tag
                        title='sale'
                        contentEditable='false'
                        spellCheck='false'
                        tabIndex={-1}
                        className='tagify__tag tagify--noAnim'
                        value='sale'
                      >
                        <x
                          title=''
                          tabIndex={-1}
                          className='tagify__tag__removeBtn'
                          role='button'
                          aria-label='remove tag'
                        />
                        <div>
                          <span className='tagify__tag-text'>sale</span>
                        </div>
                      </tag>
                      <span
                        contentEditable=''
                        tabIndex={0}
                        data-placeholder='​'
                        aria-placeholder=''
                        className='tagify__input'
                        role='textbox'
                        aria-autocomplete='both'
                        aria-multiline='false'
                      />
                    </tags> */}
                {/* <input
                      id='kt_ecommerce_add_product_tags'
                      name='kt_ecommerce_add_product_tags'
                      className='form-control mb-2'
                      defaultValue='new, trending, sale'
                      tabIndex={-1}
                    /> */}
                {/* <div className='text-muted fs-7'>Add tags to a product.</div> */}
              </div>
            </div>
            <div className='card card-flush  '>
              <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    {/* <span className='fs-4 fw-semibold text-gray-500 me-1 align-self-start'>
                          $
                        </span> */}
                    <span className='fs-2hx  text-gray-900 me-2 lh-1 ls-n2'>2,420</span>
                    <span className='badge badge-light-success fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-success ms-n1'>
                        <span className='path1' />
                        <span className='path2' />
                      </i>
                      2.6%
                    </span>
                  </div>
                  <span className='text-gray-500 pt-1 fw-semibold fs-6'>Average Daily Sales</span>
                </div>
              </div>
              <div className='card-body p-0 p-0'>
                <ReactApexChart
                  options={apexOrdersByDay?.options || {}}
                  series={apexOrdersByDay?.series || {}}
                  type='bar'
                  height={200}
                />
              </div>
            </div>
            {/* <div className='card card-flush py-4'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2>Product Template</h2>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    <label htmlFor='kt_ecommerce_add_product_store_template' className='form-label'>
                      Select a product template
                    </label>
                    <select
                      className='form-select mb-2 select2-hidden-accessible'
                      data-control='select2'
                      data-hide-search='true'
                      data-placeholder='Select an option'
                      id='kt_ecommerce_add_product_store_template'
                      data-select2-id='select2-data-kt_ecommerce_add_product_store_template'
                      tabIndex={-1}
                      aria-hidden='true'
                      data-kt-initialized={1}
                    >
                      <option />
                      <option
                        value='default'
                        // selected=""
                        data-select2-id='select2-data-15-2f1m'
                      >
                        Default template
                      </option>
                      <option value='electronics'>Electronics</option>
                      <option value='office'>Office stationary</option>
                      <option value='fashion'>Fashion</option>
                    </select>
                    <div className='text-muted fs-7'>
                      Assign a template from your current theme to define how a single product is
                      displayed.
                    </div>
                  </div>
                </div> */}
          </div>
          <div className='d-flex flex-column flex-row-fluid gap-5 gap-lg-5'>
            <ul
              className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'
              role='tablist'
            >
              <li className='nav-item' role='presentation'>
                <a
                  className='nav-link text-active-primary pb-2 active'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_general'
                  aria-selected='true'
                  role='tab'
                >
                  {intl.formatMessage({id: 'COMMON.GENERAL'})}
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  className='nav-link text-active-primary pb-2'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_modifiers'
                  aria-selected='true'
                  role='tab'
                >
                  {intl.formatMessage({id: 'COMMON.MODIFIERS'})}
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  className='nav-link text-active-primary pb-2'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_recommended'
                  aria-selected='true'
                  role='tab'
                >
                  {intl.formatMessage({id: 'COMMON.RECOMMENDED'})}
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  className='nav-link text-active-primary pb-2'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_advanced'
                  aria-selected='false'
                  tabIndex={-1}
                  role='tab'
                >
                  Advanced
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  className='nav-link text-active-primary pb-2'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_reviews'
                  aria-selected='false'
                  tabIndex={-1}
                  role='tab'
                >
                  Reviews
                </a>
              </li>
            </ul>
            <div className='tab-content'>
              <div
                className='tab-pane fade show active'
                id='kt_ecommerce_add_product_general'
                role='tab-panel'
              >
                <div className='d-flex flex-column gap-5 gap-lg-5'>
                  <div className='card card-stretch shadow mb-2'>
                    <div className='card-body main-overflow-x'>
                      <div className='table-responsive'>
                        <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-1 gs-1'>
                          <tbody>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'COMMON.NAME'})}
                              </th>
                              <td>
                                {data.name_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                              </td>
                            </tr>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'COMMON.DESCRIPTION'})}
                              </th>
                              <td>
                                {data.description_ru ||
                                  intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                              </td>
                            </tr>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'COMMON.URL'})}
                              </th>
                              <td>{data.url || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                            </tr>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'COMMON.PRICE'})}
                              </th>
                              <td>
                                {convertToPrice(data?.price || 0) +
                                  ' ' +
                                  intl.formatMessage({id: 'COMMON.SUM'})}
                              </td>
                            </tr>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'COMMON.POSITION'})}
                              </th>
                              <td>
                                {data.position || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                              </td>
                            </tr>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'ITEM.SEO_TITLE_RU'})}
                              </th>
                              <td>
                                {data.seo_title_ru ||
                                  intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                              </td>
                            </tr>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'ITEM.SEO_DESCRIPTION_RU'})}
                              </th>
                              <td>
                                {data.seo_description_ru ||
                                  intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                              </td>
                            </tr>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'ITEM.SEO_KEYWORD_RU'})}
                              </th>
                              <td>
                                {data.seo_keywords_ru ||
                                  intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                              </td>
                            </tr>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'COMMON.AVAILABLE'})}
                              </th>
                              <td>
                                {data.is_active == 1
                                  ? intl.formatMessage({id: 'COMMON.YES'})
                                  : intl.formatMessage({id: 'COMMON.NO'})}
                              </td>
                            </tr>
                            <tr>
                              <th className=' w-200px'>
                                {intl.formatMessage({id: 'COMMON.AVAILABLE_ONLINE'})}
                              </th>
                              <td>
                                {data.is_active_online == 1
                                  ? intl.formatMessage({id: 'COMMON.YES'})
                                  : intl.formatMessage({id: 'COMMON.NO'})}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>General</h2>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row fv-plugins-icon-container'>
                        <label className='required form-label'>Product Name</label>
                        <input
                          type='text'
                          name='product_name'
                          className='form-control mb-2'
                          placeholder='Product name'
                          defaultValue='Sample product'
                        />
                        <div className='text-muted fs-7'>
                          A product name is required and recommended to be unique.
                        </div>
                        <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                      </div>
                    </div>
                  </div>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Media</h2>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <div className='fv-row mb-2'>
                        <div className='dropzone dz-clickable' id='kt_ecommerce_add_product_media'>
                          <div className='dz-message needsclick'>
                            <i className='ki-duotone ki-file-up text-primary fs-3x'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>
                            <div className='ms-4'>
                              <h3 className='fs-5  text-gray-900 mb-1'>
                                Drop files here or click to upload.
                              </h3>
                              <span className='fs-7 fw-semibold text-gray-500'>
                                Upload up to 10 files
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='text-muted fs-7'>Set the product media gallery.</div>
                    </div>
                  </div>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Pricing</h2>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row fv-plugins-icon-container'>
                        <label className='required form-label'>Base Price</label>
                        <input
                          type='text'
                          name='price'
                          className='form-control mb-2'
                          placeholder='Product price'
                          defaultValue='199.99'
                        />
                        <div className='text-muted fs-7'>Set the product price.</div>
                        <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='fs-6 fw-semibold mb-2'>
                          Discount Type
                          <span
                            className='ms-1'
                            data-bs-toggle='tooltip'
                            aria-label='Select a discount type that will be applied to this product'
                            data-bs-original-title='Select a discount type that will be applied to this product'
                            data-kt-initialized={1}
                          >
                            <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </i>
                          </span>{' '}
                        </label>
                        <div
                          className='row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9'
                          data-kt-buttons='true'
                          data-kt-buttons-target="[data-kt-button='true']"
                          data-kt-initialized={1}
                        >
                          <div className='col'>
                            <label
                              className='btn btn-outline btn-outline-dashed btn-active-light-primary  d-flex text-start p-6'
                              data-kt-button='true'
                            >
                              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='discount_option'
                                  defaultValue={1}
                                />
                              </span>
                              <span className='ms-5'>
                                <span className='fs-4  text-gray-800 d-block'>
                                  No Discount
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className='col'>
                            <label
                              className='btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex text-start p-6'
                              data-kt-button='true'
                            >
                              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='discount_option'
                                  defaultValue={2}
                                  // defaultChecked='checked'
                                />
                              </span>
                              <span className='ms-5'>
                                <span className='fs-4  text-gray-800 d-block'>
                                  Percentage %
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className='col'>
                            <label
                              className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6'
                              data-kt-button='true'
                            >
                              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='discount_option'
                                  defaultValue={3}
                                />
                              </span>
                              <span className='ms-5'>
                                <span className='fs-4  text-gray-800 d-block'>
                                  Fixed Price
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className=' mb-10 fv-row'
                        id='kt_ecommerce_add_product_discount_percentage'
                      >
                        <label className='form-label'>Set Discount Percentage</label>
                        <div className='d-flex flex-column text-center mb-5'>
                          <div className='d-flex align-items-start justify-content-center mb-7'>
                            <span
                              className=' fs-3x'
                              id='kt_ecommerce_add_product_discount_label'
                            >
                              10
                            </span>
                            <span className=' fs-4 mt-1 ms-2'>%</span>
                          </div>
                          <div
                            id='kt_ecommerce_add_product_discount_slider'
                            className='noUi-sm noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr'
                          >
                            <div className='noUi-base'>
                              <div className='noUi-connects' />
                              <div
                                className='noUi-origin'
                                style={{
                                  transform: 'translate(-90.9091%, 0px)',
                                  zIndex: 4,
                                }}
                              >
                                {/* <div
                                      className='noUi-handle noUi-handle-lower'
                                      data-handle={0}
                                      tabIndex={0}
                                      role='slider'
                                      aria-orientation='horizontal'
                                      aria-valuemin={1.0}
                                      aria-valuemax={100.0}
                                      aria-valuenow={10.0}
                                      aria-valuetext={10.0}
                                    >
                                      <div className='noUi-touch-area' />
                                    </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='text-muted fs-7'>
                          Set a percentage discount to be applied on this product.
                        </div>
                      </div>
                      <div
                        className='d-none mb-10 fv-row'
                        id='kt_ecommerce_add_product_discount_fixed'
                      >
                        <label className='form-label'>Fixed Discounted Price</label>
                        <input
                          type='text'
                          name='dicsounted_price'
                          className='form-control mb-2'
                          placeholder='Discounted price'
                        />
                        <div className='text-muted fs-7'>
                          Set the discounted product price. The product will be reduced at the
                          determined fixed price
                        </div>
                      </div>
                      <div className='d-flex flex-wrap gap-5'>
                        <div className='fv-row w-100 flex-md-root fv-plugins-icon-container'>
                          <label className='required form-label'>Tax Class</label>
                          <select
                            className='form-select mb-2 select2-hidden-accessible'
                            name='tax'
                            data-control='select2'
                            data-hide-search='true'
                            data-placeholder='Select an option'
                            data-select2-id='select2-data-16-zjmq'
                            tabIndex={-1}
                            aria-hidden='true'
                            data-kt-initialized={1}
                          >
                            <option />
                            <option value={0}>Tax Free</option>
                            <option
                              value={1}
                              // selected=""
                              data-select2-id='select2-data-18-4r36'
                            >
                              Taxable Goods
                            </option>
                            <option value={2}>Downloadable Product</option>
                          </select>
                          <span
                            className='select2 select2-container select2-container--bootstrap5'
                            dir='ltr'
                            data-select2-id='select2-data-17-awgl'
                            style={{width: '100%'}}
                          >
                            <span className='selection'>
                              <span
                                className='select2-selection select2-selection--single form-select mb-2'
                                role='combobox'
                                aria-haspopup='true'
                                aria-expanded='false'
                                tabIndex={0}
                                aria-disabled='false'
                                aria-labelledby='select2-tax-cr-container'
                                aria-controls='select2-tax-cr-container'
                              >
                                <span
                                  className='select2-selection__rendered'
                                  id='select2-tax-cr-container'
                                  role='textbox'
                                  aria-readonly='true'
                                  title='Taxable Goods'
                                >
                                  Taxable Goods
                                </span>
                                <span className='select2-selection__arrow' role='presentation'>
                                  <b role='presentation' />
                                </span>
                              </span>
                            </span>
                            <span className='dropdown-wrapper' aria-hidden='true' />
                          </span>
                          <div className='text-muted fs-7'>Set the product tax class.</div>
                          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                        </div>
                        <div className='fv-row w-100 flex-md-root'>
                          <label className='form-label'>VAT Amount (%)</label>
                          <input type='text' className='form-control mb-2' defaultValue={35} />
                          <div className='text-muted fs-7'>Set the product VAT about.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tab-pane fade' id='kt_ecommerce_modifiers' role='tab-panel'>
                <div className='card card-flush py-4'>
                  <div className='card-header min-h-auto'>
                    <div className='card-title m-0 p-0'>
                      <h2>{intl.formatMessage({id: 'COMMON.MODIFIERS'})}</h2>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='table-responsive'>
                      <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-1 gs-1'>
                        <thead>
                          <tr className=' bg-light'>
                            <th>#</th>
                            <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                            <th className='text-center'>
                              {intl.formatMessage({id: 'COMMON.MAX_AMOUNT'})}
                            </th>
                            <th className='text-center'>
                              {intl.formatMessage({id: 'COMMON.MIN_AMOUNT'})}
                            </th>
                            <th className='text-center'>
                              {intl.formatMessage({id: 'COMMON.STATUS'})}
                            </th>
                            <th>{intl.formatMessage({id: 'COMMON.PRICE'})}</th>
                          </tr>
                        </thead>
                        <tbody>{getDataContent(modifiers)}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tab-pane fade' id='kt_ecommerce_recommended' role='tab-panel'>
                <div className='card card-flush py-4'>
                  <div className='card-header min-h-auto'>
                    <div className='card-title m-0 p-0'>
                      <h2>{intl.formatMessage({id: 'COMMON.RECOMMENDED'})}</h2>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='table-responsive'>
                      <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-1 gs-1'>
                        <thead>
                          <tr className=' bg-light'>
                            <th className='w-25px'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value='1'
                                  checked={products.length <= selectedItems.length}
                                  onChange={(e) => {
                                    changeSelectedItems(products, true)
                                  }}
                                />
                              </div>
                            </th>
                            <th>#</th>
                            <th>{intl.formatMessage({id: 'COMMON.IMAGE'})}</th>
                            <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                            <th>{intl.formatMessage({id: 'COMMON.URL'})}</th>
                            <th>{intl.formatMessage({id: 'COMMON.PRICE'})}</th>
                          </tr>
                        </thead>
                        <tbody>{getRecommendedContent(products)}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='kt_ecommerce_add_product_advanced'
                role='tab-panel'
              >
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Inventory</h2>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row fv-plugins-icon-container'>
                        <label className='required form-label'>SKU</label>
                        <input
                          type='text'
                          name='sku'
                          className='form-control mb-2'
                          placeholder='SKU Number'
                          // defaultValue={011985001}
                        />
                        <div className='text-muted fs-7'>Enter the product SKU.</div>
                        <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                      </div>
                      <div className='mb-10 fv-row fv-plugins-icon-container'>
                        <label className='required form-label'>Barcode</label>
                        <input
                          type='text'
                          name='barcode'
                          className='form-control mb-2'
                          placeholder='Barcode Number'
                          defaultValue={45874521458}
                        />
                        <div className='text-muted fs-7'>Enter the product barcode number.</div>
                        <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                      </div>
                      <div className='mb-10 fv-row fv-plugins-icon-container'>
                        <label className='required form-label'>Quantity</label>
                        <div className='d-flex gap-3'>
                          <input
                            type='number'
                            name='shelf'
                            className='form-control mb-2'
                            placeholder='On shelf'
                            defaultValue={24}
                          />
                          <input
                            type='number'
                            name='warehouse'
                            className='form-control mb-2'
                            placeholder='In warehouse'
                          />
                        </div>
                        <div className='text-muted fs-7'>Enter the product quantity.</div>
                        <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                      </div>
                      <div className='fv-row'>
                        <label className='form-label'>Allow Backorders</label>
                        <div className='form-check form-check-custom form-check-solid mb-2'>
                          <input className='form-check-input' type='checkbox' defaultValue='' />
                          <label className='form-check-label'>Yes</label>
                        </div>
                        <div className='text-muted fs-7'>
                          Allow customers to purchase products that are out of stock.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Variations</h2>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <div className='' data-kt-ecommerce-catalog-add-product='auto-options'>
                        <label className='form-label'>Add Product Variations</label>
                        <div id='kt_ecommerce_add_product_options'>
                          <div className='form-group'>
                            <div
                              data-repeater-list='kt_ecommerce_add_product_options'
                              className='d-flex flex-column gap-3'
                            >
                              <div
                                data-repeater-item=''
                                className='form-group d-flex flex-wrap align-items-center gap-5'
                              >
                                <div className='w-100 w-md-200px'>
                                  <select
                                    className='form-select select2-hidden-accessible'
                                    name='kt_ecommerce_add_product_options[0][product_option]'
                                    data-placeholder='Select a variation'
                                    data-kt-ecommerce-catalog-add-product='product_option'
                                    data-select2-id='select2-data-121-ftue'
                                    tabIndex={-1}
                                    aria-hidden='true'
                                  >
                                    <option data-select2-id='select2-data-123-riqs' />
                                    <option value='color'>Color</option>
                                    <option value='size'>Size</option>
                                    <option value='material'>Material</option>
                                    <option value='style'>Style</option>
                                  </select>
                                  <span
                                    className='select2 select2-container select2-container--bootstrap5'
                                    dir='ltr'
                                    data-select2-id='select2-data-122-3996'
                                    style={{width: '100%'}}
                                  >
                                    <span className='selection'>
                                      <span
                                        className='select2-selection select2-selection--single form-select'
                                        role='combobox'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                        tabIndex={0}
                                        aria-disabled='false'
                                        aria-labelledby='select2-kt_ecommerce_add_product_options0product_option-v3-container'
                                        aria-controls='select2-kt_ecommerce_add_product_options0product_option-v3-container'
                                      >
                                        <span
                                          className='select2-selection__rendered'
                                          id='select2-kt_ecommerce_add_product_options0product_option-v3-container'
                                          role='textbox'
                                          aria-readonly='true'
                                          title='Select a variation'
                                        >
                                          <span className='select2-selection__placeholder'>
                                            Select a variation
                                          </span>
                                        </span>
                                        <span
                                          className='select2-selection__arrow'
                                          role='presentation'
                                        >
                                          <b role='presentation' />
                                        </span>
                                      </span>
                                    </span>
                                    <span className='dropdown-wrapper' aria-hidden='true' />
                                  </span>
                                </div>
                                <input
                                  type='text'
                                  className='form-control mw-100 w-200px'
                                  name='kt_ecommerce_add_product_options[0][product_option_value]'
                                  placeholder='Variation'
                                />
                                <button
                                  type='button'
                                  data-repeater-delete=''
                                  className='btn btn-sm btn-icon btn-light-danger'
                                >
                                  <i className='ki-duotone ki-cross fs-1'>
                                    <span className='path1' />
                                    <span className='path2' />
                                  </i>{' '}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className='form-group mt-5'>
                            <button
                              type='button'
                              data-repeater-create=''
                              className='btn btn-sm btn-light-primary'
                            >
                              <i className='ki-duotone ki-plus fs-2' /> Add another variation
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Shipping</h2>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <div className='fv-row'>
                        <div className='form-check form-check-custom form-check-solid mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='kt_ecommerce_add_product_shipping_checkbox'
                            defaultValue={1}
                            // defaultChecked=""
                          />
                          <label className='form-check-label'>This is a physical product</label>
                        </div>
                        <div className='text-muted fs-7'>
                          Set if the product is a physical or digital item. Physical products may
                          require shipping.
                        </div>
                      </div>
                      <div id='kt_ecommerce_add_product_shipping' className=' mt-10'>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Weight</label>
                          <input
                            type='text'
                            name='weight'
                            className='form-control mb-2'
                            placeholder='Product weight'
                            defaultValue='4.3'
                          />
                          <div className='text-muted fs-7'>
                            Set a product weight in kilograms (kg).
                          </div>
                        </div>
                        <div className='fv-row'>
                          <label className='form-label'>Dimension</label>
                          <div className='d-flex flex-wrap flex-sm-nowrap gap-3'>
                            <input
                              type='number'
                              name='width'
                              className='form-control mb-2'
                              placeholder='Width (w)'
                              defaultValue={12}
                            />
                            <input
                              type='number'
                              name='height'
                              className='form-control mb-2'
                              placeholder='Height (h)'
                              defaultValue={4}
                            />
                            <input
                              type='number'
                              name='length'
                              className='form-control mb-2'
                              placeholder='Lengtn (l)'
                              defaultValue='8.5'
                            />
                          </div>
                          <div className='text-muted fs-7'>
                            Enter the product dimensions in centimeters (cm).
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Meta Options</h2>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <div className='mb-10'>
                        <label className='form-label'>Meta Tag Title</label>
                        <input
                          type='text'
                          className='form-control mb-2'
                          name='meta_title'
                          placeholder='Meta tag name'
                        />
                        <div className='text-muted fs-7'>
                          Set a meta tag title. Recommended to be simple and precise keywords.
                        </div>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label'>Meta Tag Description</label>
                        <div className='ql-toolbar ql-snow'>
                          <span className='ql-formats'>
                            <span className='ql-header ql-picker'>
                              <span
                                className='ql-picker-label'
                                tabIndex={0}
                                role='button'
                                aria-expanded='false'
                                aria-controls='ql-picker-options-1'
                              >
                                <svg viewBox='0 0 18 18'>
                                  {' '}
                                  <polygon
                                    className='ql-stroke'
                                    points='7 11 9 13 11 11 7 11'
                                  />{' '}
                                  <polygon className='ql-stroke' points='7 7 9 5 11 7 7 7' />{' '}
                                </svg>
                              </span>
                              <span
                                className='ql-picker-options'
                                aria-hidden='true'
                                tabIndex={-1}
                                id='ql-picker-options-1'
                              >
                                <span
                                  tabIndex={0}
                                  role='button'
                                  className='ql-picker-item'
                                  data-value={1}
                                />
                                <span
                                  tabIndex={0}
                                  role='button'
                                  className='ql-picker-item'
                                  data-value={2}
                                />
                                <span
                                  tabIndex={0}
                                  role='button'
                                  className='ql-picker-item ql-selected'
                                />
                              </span>
                            </span>
                            <select className='ql-header' style={{display: 'none'}}>
                              <option value={1} />
                              <option value={2} />
                              {/* <option selected="selected" /> */}
                            </select>
                          </span>
                          <span className='ql-formats'>
                            <button type='button' className='ql-bold'>
                              <svg viewBox='0 0 18 18'>
                                {' '}
                                <path
                                  className='ql-stroke'
                                  d='M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z'
                                />{' '}
                                <path
                                  className='ql-stroke'
                                  d='M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z'
                                />{' '}
                              </svg>
                            </button>
                            <button type='button' className='ql-italic'>
                              <svg viewBox='0 0 18 18'>
                                {' '}
                                <line className='ql-stroke' x1={7} x2={13} y1={4} y2={4} />{' '}
                                <line className='ql-stroke' x1={5} x2={11} y1={14} y2={14} />{' '}
                                <line className='ql-stroke' x1={8} x2={10} y1={14} y2={4} />{' '}
                              </svg>
                            </button>
                            <button type='button' className='ql-underline'>
                              <svg viewBox='0 0 18 18'>
                                {' '}
                                <path
                                  className='ql-stroke'
                                  d='M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3'
                                />{' '}
                                <rect
                                  className='ql-fill'
                                  height={1}
                                  rx='0.5'
                                  ry='0.5'
                                  width={12}
                                  x={3}
                                  y={15}
                                />{' '}
                              </svg>
                            </button>
                          </span>
                          <span className='ql-formats'>
                            <button type='button' className='ql-image'>
                              <svg viewBox='0 0 18 18'>
                                {' '}
                                <rect
                                  className='ql-stroke'
                                  height={10}
                                  width={12}
                                  x={3}
                                  y={4}
                                />{' '}
                                <circle className='ql-fill' cx={6} cy={7} r={1} />{' '}
                                <polyline
                                  className='ql-even ql-fill'
                                  points='5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12'
                                />{' '}
                              </svg>
                            </button>
                            <button type='button' className='ql-code-block'>
                              <svg viewBox='0 0 18 18'>
                                {' '}
                                <polyline
                                  className='ql-even ql-stroke'
                                  points='5 7 3 9 5 11'
                                />{' '}
                                <polyline className='ql-even ql-stroke' points='13 7 15 9 13 11' />{' '}
                                <line className='ql-stroke' x1={10} x2={8} y1={5} y2={13} />{' '}
                              </svg>
                            </button>
                          </span>
                        </div>
                        <div
                          id='kt_ecommerce_add_product_meta_description'
                          // name='kt_ecommerce_add_product_meta_description'
                          className='min-h-100px mb-2 ql-container ql-snow'
                        >
                          <div
                            className='ql-editor ql-blank'
                            data-gramm='false'
                            // contentEditable='true'
                            data-placeholder='Type your text here...'
                          >
                            <p>
                              <br />
                            </p>
                          </div>
                          <div className='ql-clipboard' tabIndex={-1} />
                          <div className='ql-tooltip ql-hidden'>
                            <a
                              className='ql-preview'
                              rel='noopener noreferrer'
                              target='_blank'
                              href='about:blank'
                            />
                            <input
                              type='text'
                              data-formula='e=mc^2'
                              data-link='https://quilljs.com'
                              data-video='Embed URL'
                            />
                            <a className='ql-action' />
                            <a className='ql-remove' />
                          </div>
                        </div>
                        <div className='text-muted fs-7'>
                          Set a meta tag description to the product for increased SEO ranking.
                        </div>
                      </div>
                      <div>
                        <label className='form-label'>Meta Tag Keywords</label>
                        <input
                          id='kt_ecommerce_add_product_meta_keywords'
                          name='kt_ecommerce_add_product_meta_keywords'
                          className='form-control mb-2'
                        />
                        <div className='text-muted fs-7'>
                          Set a list of keywords that the product is related to. Separate the
                          keywords by adding a comma <code>,</code> between each keyword.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tab-pane fade' id='kt_ecommerce_add_product_reviews' role='tab-panel'>
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Customer Reviews</h2>
                      </div>
                      <div className='card-toolbar'>
                        <span className=' me-5'>Overall Rating: </span>
                        <div className='rating'>
                          <div className='rating-label checked'>
                            <i className='ki-duotone ki-star fs-2' />{' '}
                          </div>
                          <div className='rating-label checked'>
                            <i className='ki-duotone ki-star fs-2' />{' '}
                          </div>
                          <div className='rating-label checked'>
                            <i className='ki-duotone ki-star fs-2' />{' '}
                          </div>
                          <div className='rating-label checked'>
                            <i className='ki-duotone ki-star fs-2' />{' '}
                          </div>
                          <div className='rating-label'>
                            <i className='ki-duotone ki-star fs-2' />{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <table
                        className='table table-row-dashed fs-6 gy-5 my-0'
                        id='kt_ecommerce_add_product_reviews'
                      >
                        <thead>
                          <tr className='text-start text-gray-500  fs-7 text-uppercase gs-0'>
                            <th className='w-10px pe-2'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  data-kt-check='true'
                                  data-kt-check-target='#kt_ecommerce_add_product_reviews .form-check-input'
                                  defaultValue={1}
                                />
                              </div>
                            </th>
                            <th className='min-w-125px'>Rating</th>
                            <th className='min-w-175px'>Customer</th>
                            <th className='min-w-175px'>Comment</th>
                            <th className='min-w-100px text-end fs-7'>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-5'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <div className='symbol-label bg-light-danger'>
                                    <span className='text-danger'>M</span>
                                  </div>
                                </div>
                                <span className=''>Melody Macy</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>I like this design </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>Today</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-5'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <span
                                    className='symbol-label'
                                    style={{
                                      backgroundImage:
                                        'url(/metronic8/demo8/assets/media/avatars/300-1.jpg)',
                                    }}
                                  />
                                </div>
                                <span className=''>Max Smith</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              Good product for outdoors or indoors{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>day ago</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-4'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label '>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <span
                                    className='symbol-label'
                                    style={{
                                      backgroundImage:
                                        'url(/metronic8/demo8/assets/media/avatars/300-5.jpg)',
                                    }}
                                  />
                                </div>
                                <span className=''>Sean Bean</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              Awesome quality with great materials used, but could be more
                              comfortable{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>11:20 PM</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-5'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <span
                                    className='symbol-label'
                                    style={{
                                      backgroundImage:
                                        'url(/metronic8/demo8/assets/media/avatars/300-25.jpg)',
                                    }}
                                  />
                                </div>
                                <span className=''>Brian Cox</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              This is the best product I've ever used.{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>2 days ago</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-3'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label '>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label '>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <div className='symbol-label bg-light-warning'>
                                    <span className='text-warning'>C</span>
                                  </div>
                                </div>
                                <span className=''>Mikaela Collins</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              I thought it was just average, I prefer other brands{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>July 25</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-5'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <span
                                    className='symbol-label'
                                    style={{
                                      backgroundImage:
                                        'url(/metronic8/demo8/assets/media/avatars/300-9.jpg)',
                                    }}
                                  />
                                </div>
                                <span className=''>Francis Mitcham</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              Beautifully crafted. Worth every penny.{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>July 24</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-4'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label '>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <div className='symbol-label bg-light-danger'>
                                    <span className='text-danger'>O</span>
                                  </div>
                                </div>
                                <span className=''>Olivia Wild</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              Awesome value for money. Shipping could be faster tho.{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>July 13</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-5'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <div className='symbol-label bg-light-primary'>
                                    <span className='text-primary'>N</span>
                                  </div>
                                </div>
                                <span className=''>Neil Owen</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              Excellent quality, I got it for my son's birthday and he loved it!{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>May 25</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-5'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <span
                                    className='symbol-label'
                                    style={{
                                      backgroundImage:
                                        'url(/metronic8/demo8/assets/media/avatars/300-23.jpg)',
                                    }}
                                  />
                                </div>
                                <span className=''>Dan Wilson</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              I got this for Christmas last year, and it's still the best product
                              I've ever used!{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>April 15</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-5'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <div className='symbol-label bg-light-danger'>
                                    <span className='text-danger'>E</span>
                                  </div>
                                </div>
                                <span className=''>Emma Bold</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              Was skeptical at first, but after using it for 3 months, I'm hooked!
                              Will definately buy another!{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>April 3</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-4'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label '>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <span
                                    className='symbol-label'
                                    style={{
                                      backgroundImage:
                                        'url(/metronic8/demo8/assets/media/avatars/300-12.jpg)',
                                    }}
                                  />
                                </div>
                                <span className=''>Ana Crown</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              Great product, too bad I missed out on the sale.{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>March 17</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-5'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <div className='symbol-label bg-light-info'>
                                    <span className='text-info'>A</span>
                                  </div>
                                </div>
                                <span className=''>Robert Doe</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              Got this on sale! Best decision ever!{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>March 12</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                            </td>
                            <td data-order='rating-5'>
                              <div className='rating'>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                                <div className='rating-label checked'>
                                  <i className='ki-duotone ki-star fs-6' />{' '}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='/metronic8/demo8/apps/inbox/reply.html'
                                className='d-flex text-gray-900 text-gray-800 text-hover-primary'
                              >
                                <div className='symbol symbol-circle symbol-25px me-3'>
                                  <span
                                    className='symbol-label'
                                    style={{
                                      backgroundImage:
                                        'url(/metronic8/demo8/assets/media/avatars/300-13.jpg)',
                                    }}
                                  />
                                </div>
                                <span className=''>John Miller</span>
                              </a>
                            </td>
                            <td className='text-gray-600 '>
                              Firesale is on! Buy now! Totally worth it!{' '}
                            </td>
                            <td className='text-end'>
                              <span className='fw-semibold text-muted'>March 11</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='d-flex justify-content-end'>
                  <a
                    href='/metronic8/demo8/apps/ecommerce/catalog/products.html'
                    id='kt_ecommerce_add_product_cancel'
                    className='btn btn-light me-5'
                  >
                    Cancel
                  </a>
                  <button
                    type='submit'
                    id='kt_ecommerce_add_product_submit'
                    className='btn btn-primary'
                  >
                    <span className='indicator-label'>Save Changes</span>
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    </span>
                  </button>
                </div> */}
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ModofierModal ref={modifierModalRef} choosenItem={choosenItem} org_id={org_id} />

      <ItemModal
        ref={itemModalRef}
        products={products}
        id={product_id}
        refreshData={refreshData}
        recommend={data?.recommend}
      />
      {isViewerOpen && (
        <ImageViewer
          // @ts-ignore
          src={viewerImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
}
