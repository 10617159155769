// COMPONENTS
import {KTSVG} from '../../../_metronic/helpers'
import FooterCopyright from '../../modules/FooterCopyright'

export function OtherPublicOfferIndex() {
  return (
    <>
      <div className='container'>
        <h1>Политика конфиденциальности</h1>
        1. Введение
        <br />
        Добро пожаловать в Nomdor Somsa Osh Markazi ("мы", "нас", "наш"). Мы обязуемся защищать вашу
        конфиденциальность и обеспечить безопасность ваших персональных данных. Эта Политика
        конфиденциальности объясняет, какие данные мы собираем, как мы их используем, и как мы
        защищаем вашу информацию.
        <br />
        2. Сбор данных
        <br />
        Мы можем собирать следующие типы данных:
        <br />
        - Личная информация: имя, контактные данные, адрес электронной почты, телефонный номер,
        адрес доставки (если применимо). - Информация о транзакциях: данные о ваших покупках,
        заказах и платежах. - Информация о посещениях: информация о том, как вы используете наш
        веб-сайт (если он есть), включая IP-адрес, тип устройства, браузер и страницы, которые вы
        посещаете. - Обратная связь: информация, которую вы предоставляете через отзывы, опросы или
        запросы.
        <br />
        3. Использование данных
        <br />
        Мы используем собранные данные для следующих целей:
        <br />
        - Обработка и выполнение ваших заказов и запросов. - Улучшение нашего обслуживания клиентов
        и качества продукции. - Связь с вами по поводу акций, специальных предложений и новостей,
        если вы согласились на получение такой информации. - Проведение анализа для улучшения нашего
        бизнеса и услуг.
        <br />
        4. Передача данных
        <br />
        Мы не передаем ваши персональные данные третьим лицам, за исключением случаев, когда это
        необходимо для выполнения наших обязательств перед вами, таких как обработка платежей или
        выполнение заказов. Мы также можем передавать данные, если это требуется по закону или в
        случае слияния, поглощения или продажи бизнеса.
        <br />
        5. Безопасность данных
        <br />
        Мы принимаем меры для защиты ваших данных от несанкционированного доступа, использования или
        раскрытия. Несмотря на это, ни один метод передачи данных через Интернет или электронное
        хранение данных не может гарантировать абсолютную безопасность, и мы не можем гарантировать
        полную защиту вашей информации.
        <br />
        6. Ваши права
        <br />
        Вы имеете право на доступ к вашим персональным данным, их исправление или удаление. Вы также
        можете отозвать согласие на обработку данных, если это применимо. Для осуществления этих
        прав, пожалуйста, свяжитесь с нами по указанным ниже контактным данным.
        <br />
        7. Использование файлов cookie
        <br />
        Если у нас есть веб-сайт, он может использовать файлы cookie для улучшения вашего опыта.
        Файлы cookie помогают нам анализировать использование сайта и предоставлять вам
        персонализированное содержание. Вы можете настроить свой браузер для отказа от использования
        файлов cookie, но это может повлиять на работу сайта.
        <br />
        8. Ссылки на сторонние сайты
        <br />
        Наш сайт или другие материалы могут содержать ссылки на сторонние сайты. Мы не несем
        ответственности за политику конфиденциальности или содержание этих сайтов. Мы рекомендуем
        вам ознакомиться с политикой конфиденциальности любого стороннего сайта, на который вы
        переходите.
        <br />
        9. Изменения в Политике конфиденциальности
        <br />
        Мы можем время от времени обновлять эту Политику конфиденциальности. Все изменения будут
        опубликованы на этой странице с указанием даты вступления в силу. Мы рекомендуем вам
        регулярно проверять эту страницу, чтобы быть в курсе изменений.
        <br />
        10. Контактная информация
        <br />
        Если у вас есть вопросы или комментарии по поводу этой Политики конфиденциальности,
        пожалуйста, свяжитесь с нами:
        <br />
        Nomdor Somsa Osh Markazi
        <br />
        Адрес: улица Шахрисабз, 21A/1, Мирабадский район, Ташкент
        <br />
        Электронная почта: nomdor.tashkent@mail.ru
        <br />
        Телефон: +998 71 207 04 04
      </div>
    </>
  )
}
