import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import moment from 'moment'
import * as Yup from 'yup'
import clsx from 'clsx'

import * as authHelper from '../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {swal} from '../../../../_metronic/helpers/swal'

import {DeleteModal} from './DeleteModal'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  let modalRef = useRef(null)
  const deleteModalRef = useRef()

  return (
    <>
      <Modal
        className='es-modal'
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
        centered
      >
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='table-responsive'>
            <table className='table table-sm gy-2 gs-7'>
              <tbody>
                <tr>
                  <th className=' fs-6 text-gray-800 w-25'>
                    {intl.formatMessage({id: 'COMMON.NAME2'})}
                  </th>
                  <td>
                    {props.item?.account?.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                  </td>
                </tr>
                <tr>
                  <th className=' fs-6 text-gray-800 w-25'>
                    {intl.formatMessage({id: 'COMMON.PHONE'})}
                  </th>
                  <td>
                    {props.item?.account?.phone || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                  </td>
                </tr>
                <tr>
                  <th className=' fs-6 text-gray-800 w-25'>
                    {intl.formatMessage({id: 'COMMON.REG_DATE'})}
                  </th>
                  <td>{moment(props.item?.account?.created_at).format('DD.MM.YYYY HH:mm')}</td>
                </tr>
                <tr>
                  <th className=' fs-6 text-gray-800 w-25'>
                    {intl.formatMessage({id: 'ITEM.TEXT_RU'})}
                  </th>
                  <td> {props.item?.text || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                </tr>
                <tr>
                  <th className=' fs-6 text-gray-800 w-25'>
                    {intl.formatMessage({id: 'COMMON.RATING'})}
                  </th>
                  <td> {props.item?.rate || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
                </tr>
                <tr>
                  <th className=' fs-6 text-gray-800 w-25'>
                    {intl.formatMessage({id: 'COMMON.DATE'})}
                  </th>
                  <td>{moment(props.item?.created_at).format('DD.MM.YYYY HH:mm')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            type='button'
            className='btn btn-danger me-auto'
            // @ts-ignore
            onClick={() => (handleClose(), deleteModalRef.current.showModal())}
          >
            {intl.formatMessage({id: 'COMMON.DELETE'})}
          </button>
          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {intl.formatMessage({id: 'COMMON.SAVE'})}
          </button> */}
        </Modal.Footer>
      </Modal>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
    </>
  )
})
