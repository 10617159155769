import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import ImageViewer from 'react-simple-image-viewer'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {getMediaUrl} from '../../../../_metronic/helpers/general'

import {AddImageModal} from './AddImageModal'
import {DeleteImageModal} from './DeleteImageModal'

export const ImagesModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [images, setImages] = useState([])
  const [choosenImage, setChoosenImage] = React.useState(null)

  const handleClose = () => setShow(false)
  const handleShow = async () => {
    setShow(true)
  }

  useEffect(() => {
    setImages(props.item?.photos || [])
  }, [props])

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const addImageModalRef = useRef()
  const deleteImageModalRef = useRef()

  // **** ImageViewer ****
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  // @ts-ignore
  let viewerImages = images.map((x) => getMediaUrl('galleries', x.image))
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])
  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  // **** End of ImageViewer ****

  const imageClick = (item: any) => {
    setChoosenImage(item)
  }

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []
    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr key={'imglist-' + value.id} className='c-pointer'>
          <td>{i + 1}</td>
          <td>
            <img
              className='row-img'
              src={getMediaUrl('galleries', value.image)}
              alt=''
              onClick={() => openImageViewer(i)}
            />
          </td>
          <td>{moment(value.created_at).format('DD/MM/YYYY HH:mm')}</td>
          <td className='text-end'>
            <button
              type='button'
              className='btn btn-danger btn-sm'
              // @ts-ignore
              onClick={() => {
                imageClick(value)
                handleClose()
                // @ts-ignore
                deleteImageModalRef.current.showModal()
              }}
            >
              {intl.formatMessage({id: 'COMMON.DELETE'})}
            </button>
          </td>
        </tr>
      )
    }

    return content
  }

  return (
    <>
      <Modal size='lg' show={show} onHide={handleClose} onShow={handleShow} centered>
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.IMAGES'})}</h5>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex mb-5'>
            <button
              className='btn btn-success w-100' // @ts-ignore
              onClick={() => (handleClose(), addImageModalRef.current.showModal())}
            >
              {intl.formatMessage({id: 'COMMON.ADD'})}
            </button>
          </div>
          <div className='table-responsive'>
            <table className='table table-sm table-striped gy-2 gs-7'>
              <thead>
                <tr className=' fs-6 text-gray-800 border-bottom border-gray-200'>
                  <th>#</th>
                  <th>{intl.formatMessage({id: 'COMMON.IMAGE'})}</th>
                  <th>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{getDataContent(images)}</tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <AddImageModal
        parentItem={props.item}
        parentRef={ref}
        ref={addImageModalRef}
        refreshData={props.refreshData}
      />
      <DeleteImageModal
        item={choosenImage}
        parentItem={props.item}
        parentRef={ref}
        ref={deleteImageModalRef}
        refreshData={props.refreshData}
      />
      {isViewerOpen && (
        <ImageViewer
          // @ts-ignore
          src={viewerImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
})
