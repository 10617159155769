import clsx from 'clsx'
import moment from 'moment'
import axios from 'axios'
import {useIntl} from 'react-intl'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import FooterCopyright from '../../../modules/FooterCopyright'
import Loader from '../../../components/Loader'
import {swal} from '../../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {useRoleContext} from '../../../context/getAccessRoleUser'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { getMediaUrl } from '../../../../_metronic/helpers/general'

export function CouriersItemIndex() {
  const intl = useIntl()
  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let {id} = useParams()

  // LOCAL STATE
  const [data, setData] = React.useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)

  async function getData() {
    try {
      const res = await axios.get(`courier/${id}`)
      setData(res.data || {})

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'COMMON.COURIER'}),
        path: '/couriers',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {data?.name || intl.formatMessage({id: 'MENU.USER'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='d-flex flex-column flex-xl-row'>
          <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
            <div className='card mb-5 mb-xl-8'>
              <div className='card-body pt-15'>
                <div className='d-flex flex-center flex-column mb-5'>
                  <div className='symbol symbol-100px symbol-circle mb-7'>
                    <img
                      src={
                        data?.image
                          ? getMediaUrl('users', data?.image)
                          : toAbsoluteUrl('/avatar.png')
                      }
                      alt={data?.name}
                    />
                  </div>
                  <a href='#' className='fs-3 text-gray-800 text-hover-primary  mb-1'>
                    {data?.name}
                  </a>
                  <div className='fs-5 fw-semibold text-muted mb-6'>
                    {intl.formatMessage({id: 'COMMON.COURIER'})}
                  </div>
                  <div className='d-flex flex-wrap flex-center'>
                    <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
                      <div className='fs-4  text-gray-700'>
                        <span className='w-75px'>6,900</span>
                        <i className='ki-duotone ki-arrow-up fs-3 text-success'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>
                      </div>
                      <div className='fw-semibold text-muted'>Earnings</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3'>
                      <div className='fs-4  text-gray-700'>
                        <span className='w-50px'>130</span>
                        <i className='ki-duotone ki-arrow-down fs-3 text-danger'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>
                      </div>
                      <div className='fw-semibold text-muted'>Tasks</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
                      <div className='fs-4  text-gray-700'>
                        <span className='w-50px'>500</span>
                        <i className='ki-duotone ki-arrow-up fs-3 text-success'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>
                      </div>
                      <div className='fw-semibold text-muted'>Hours</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-stack fs-4 py-3'>
                  <div
                    className=' rotate collapsible'
                    data-bs-toggle='collapse'
                    role='button'
                    aria-expanded='false'
                    aria-controls='kt_customer_view_details'
                  >
                    {intl.formatMessage({id: 'COMMON.DETAILS'})}
                  </div>
                  <div
                    className={clsx(
                      'badge badge-light-success ',
                      {'badge-light-success': data?.is_active},
                      {
                        'badge-light-danger': !data?.is_active,
                      }
                    )}
                  >
                    {data?.is_active
                      ? intl.formatMessage({id: 'COMMON.ACTIVE'})
                      : intl.formatMessage({id: 'COMMON.NOT_ACTIVE'})}
                  </div>
                </div>
                <div className='separator separator-dashed' />
                <div id='kt_customer_view_details' className='collapse show'>
                  <div className='py-5 fs-6'>
                    <div className=' mt-5'>
                      {intl.formatMessage({id: 'COMMON.ACCOUNT_ID'})}
                    </div>
                    <div className='text-gray-600'>ID-{data?.id}</div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.PHONE'})}</div>
                    <div className='text-gray-600'>{data?.phone || '-'}</div>
                    <div className=' mt-5'>{intl.formatMessage({id: 'COMMON.BRANCH'})}</div>
                    <div className='text-gray-600'>{data?.terminal?.name_ru || '-'}</div>
                    <div className=' mt-5'>
                      {intl.formatMessage({id: 'COMMON.LAST_VISIT'})}
                    </div>
                    <div className='text-gray-600'>
                      {moment(data?.last_location).format('MMMM Do YYYY, h:mm:ss a')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='flex-lg-row-fluid ms-lg-5'>
            <ul
              className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-5'
              role='tablist'
            >
              <li className='nav-item' role='presentation'>
                <a
                  className='nav-link text-active-primary pb-2 active'
                  data-bs-toggle='tab'
                  href='#kt_customer_view_overview_tab'
                  aria-selected='true'
                  role='tab'
                >
                  Overview
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  className='nav-link text-active-primary pb-2'
                  data-bs-toggle='tab'
                  href='#kt_customer_view_overview_events_and_logs_tab'
                  aria-selected='false'
                  role='tab'
                  tabIndex={-1}
                >
                  Events &amp; Logs
                </a>
              </li>
              <li className='nav-item' role='presentation'>
                <a
                  className='nav-link text-active-primary pb-2'
                  data-kt-countup-tabs='true'
                  data-bs-toggle='tab'
                  href='#kt_customer_view_overview_statements'
                  data-kt-initialized={1}
                  aria-selected='false'
                  role='tab'
                  tabIndex={-1}
                >
                  Statements
                </a>
              </li>
            </ul>
            <div className='tab-content' id='myTabContent'>
              <div
                className='tab-pane fade active show'
                id='kt_customer_view_overview_tab'
                role='tabpanel'
              >
                <div className='card pt-4 mb-6 mb-xl-9'>
                  <div className='card-header border-0'>
                    <div className='card-title'>
                      <h2>Payment Records</h2>
                    </div>
                  </div>
                  <div className='card-body pt-0 pb-5'>
                    <div
                      id='kt_table_customers_payment_wrapper'
                      className='dt-container dt-bootstrap5 dt-empty-footer'
                    >
                      <div id='' className='table-responsive'>
                        <table
                          className='table align-middle table-row-dashed gy-5 dataTable'
                          id='kt_table_customers_payment'
                          style={{width: '471.78px'}}
                        >
                          <colgroup>
                            <col style={{width: 100}} />
                            <col style={{width: '91.59px'}} />
                            <col style={{width: '80.19px'}} />
                            <col style={{width: 100}} />
                            <col style={{width: 100}} />
                          </colgroup>
                          <thead className='border-bottom border-gray-200 fs-7 '>
                            <tr className='text-start text-muted text-uppercase gs-0' role='row'>
                              <th
                                className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                data-dt-column={0}
                                rowSpan={1}
                                colSpan={1}
                                aria-label='Invoice No.: Activate to sort'
                                tabIndex={0}
                              >
                                <span className='dt-column-title' role='button'>
                                  Invoice No.
                                </span>
                                <span className='dt-column-order' />
                              </th>
                              <th
                                data-dt-column={1}
                                rowSpan={1}
                                colSpan={1}
                                className='dt-orderable-asc dt-orderable-desc'
                                aria-label='Status: Activate to sort'
                                tabIndex={0}
                              >
                                <span className='dt-column-title' role='button'>
                                  Status
                                </span>
                                <span className='dt-column-order' />
                              </th>
                              <th
                                data-dt-column={2}
                                rowSpan={1}
                                colSpan={1}
                                className='dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                aria-label='Amount: Activate to sort'
                                tabIndex={0}
                              >
                                <span className='dt-column-title' role='button'>
                                  Amount
                                </span>
                                <span className='dt-column-order' />
                              </th>
                              <th
                                className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                data-dt-column={3}
                                rowSpan={1}
                                colSpan={1}
                                aria-label='Date: Activate to sort'
                                tabIndex={0}
                              >
                                <span className='dt-column-title' role='button'>
                                  Date
                                </span>
                                <span className='dt-column-order' />
                              </th>
                              <th
                                className='text-end min-w-100px pe-4 dt-orderable-none'
                                data-dt-column={4}
                                rowSpan={1}
                                colSpan={1}
                                aria-label='Actions'
                              >
                                <span className='dt-column-title'>Actions</span>
                                <span className='dt-column-order' />
                              </th>
                            </tr>
                          </thead>
                          <tbody className='fs-6 fw-semibold text-gray-600'>
                            <tr>
                              <td>
                                <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                                  8994-6884
                                </a>
                              </td>
                              <td>
                                <span className='badge badge-light-success'>Successful</span>
                              </td>
                              <td className='dt-type-numeric'>$1,200.00 </td>
                              <td data-order='2020-12-14T20:43:00+05:00'>14 Dec 2020, 8:43 pm </td>
                              <td className='pe-0 text-end'>
                                <a
                                  href='#'
                                  className='btn btn-sm btn-light image.png btn-active-light-primary'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                >
                                  Actions
                                  <i className='ki-duotone ki-down fs-5 ms-1' />
                                </a>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  <div className='menu-item px-3'>
                                    <a
                                      href='/metronic8/demo8/apps/customers/view.html'
                                      className='menu-link px-3'
                                    >
                                      View
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      href='#'
                                      className='menu-link px-3'
                                      data-kt-customer-table-filter='delete_row'
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                                  1754-8970
                                </a>
                              </td>
                              <td>
                                <span className='badge badge-light-success'>Successful</span>
                              </td>
                              <td className='dt-type-numeric'>$79.00 </td>
                              <td data-order='2020-12-01T10:12:00+05:00'>01 Dec 2020, 10:12 am </td>
                              <td className='pe-0 text-end'>
                                <a
                                  href='#'
                                  className='btn btn-sm btn-light image.png btn-active-light-primary'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                >
                                  Actions
                                  <i className='ki-duotone ki-down fs-5 ms-1' />
                                </a>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  <div className='menu-item px-3'>
                                    <a
                                      href='/metronic8/demo8/apps/customers/view.html'
                                      className='menu-link px-3'
                                    >
                                      View
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      href='#'
                                      className='menu-link px-3'
                                      data-kt-customer-table-filter='delete_row'
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                                  9292-6992
                                </a>
                              </td>
                              <td>
                                <span className='badge badge-light-success'>Successful</span>
                              </td>
                              <td className='dt-type-numeric'>$5,500.00 </td>
                              <td data-order='2020-11-12T14:01:00+05:00'>12 Nov 2020, 2:01 pm </td>
                              <td className='pe-0 text-end'>
                                <a
                                  href='#'
                                  className='btn btn-sm btn-light image.png btn-active-light-primary'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                >
                                  Actions
                                  <i className='ki-duotone ki-down fs-5 ms-1' />
                                </a>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  <div className='menu-item px-3'>
                                    <a
                                      href='/metronic8/demo8/apps/customers/view.html'
                                      className='menu-link px-3'
                                    >
                                      View
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      href='#'
                                      className='menu-link px-3'
                                      data-kt-customer-table-filter='delete_row'
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                                  7993-2448
                                </a>
                              </td>
                              <td>
                                <span className='badge badge-light-warning'>Pending</span>
                              </td>
                              <td className='dt-type-numeric'>$880.00 </td>
                              <td data-order='2020-10-21T17:54:00+05:00'>21 Oct 2020, 5:54 pm </td>
                              <td className='pe-0 text-end'>
                                <a
                                  href='#'
                                  className='btn btn-sm btn-light image.png btn-active-light-primary'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                >
                                  Actions
                                  <i className='ki-duotone ki-down fs-5 ms-1' />
                                </a>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  <div className='menu-item px-3'>
                                    <a
                                      href='/metronic8/demo8/apps/customers/view.html'
                                      className='menu-link px-3'
                                    >
                                      View
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      href='#'
                                      className='menu-link px-3'
                                      data-kt-customer-table-filter='delete_row'
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                                  2347-7022
                                </a>
                              </td>
                              <td>
                                <span className='badge badge-light-success'>Successful</span>
                              </td>
                              <td className='dt-type-numeric'>$7,650.00 </td>
                              <td data-order='2020-10-19T07:32:00+05:00'>19 Oct 2020, 7:32 am </td>
                              <td className='pe-0 text-end'>
                                <a
                                  href='#'
                                  className='btn btn-sm btn-light image.png btn-active-light-primary'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                >
                                  Actions
                                  <i className='ki-duotone ki-down fs-5 ms-1' />
                                </a>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  <div className='menu-item px-3'>
                                    <a
                                      href='/metronic8/demo8/apps/customers/view.html'
                                      className='menu-link px-3'
                                    >
                                      View
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      href='#'
                                      className='menu-link px-3'
                                      data-kt-customer-table-filter='delete_row'
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot />
                        </table>
                      </div>
                      <div id='' className='row'>
                        <div
                          id=''
                          className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                        />
                        <div
                          id=''
                          className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                        >
                          <div className='dt-paging paging_simple_numbers'>
                            <ul className='pagination'>
                              <li className='dt-paging-button page-item disabled'>
                                <a
                                  className='page-link previous'
                                  aria-controls='kt_table_customers_payment'
                                  aria-disabled='true'
                                  aria-label='Previous'
                                  data-dt-idx='previous'
                                  tabIndex={-1}
                                >
                                  <i className='previous' />
                                </a>
                              </li>
                              <li className='dt-paging-button page-item active'>
                                <a
                                  href='#'
                                  className='page-link'
                                  aria-controls='kt_table_customers_payment'
                                  aria-current='page'
                                  data-dt-idx={0}
                                  tabIndex={0}
                                >
                                  1
                                </a>
                              </li>
                              <li className='dt-paging-button page-item'>
                                <a
                                  href='#'
                                  className='page-link'
                                  aria-controls='kt_table_customers_payment'
                                  data-dt-idx={1}
                                  tabIndex={0}
                                >
                                  2
                                </a>
                              </li>
                              <li className='dt-paging-button page-item'>
                                <a
                                  href='#'
                                  className='page-link next'
                                  aria-controls='kt_table_customers_payment'
                                  aria-label='Next'
                                  data-dt-idx='next'
                                  tabIndex={0}
                                >
                                  <i className='next' />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card pt-4 mb-6 mb-xl-9'>
                  <div className='card-header border-0'>
                    <div className='card-title'>
                      <h2 className=' mb-0'>Payment Methods</h2>
                    </div>
                  </div>
                  <div id='kt_customer_view_payment_method' className='card-body pt-0'>
                    <div className='py-0' data-kt-customer-payment-method='row'>
                      <div className='py-3 d-flex flex-stack flex-wrap'>
                        <div
                          className='d-flex align-items-center collapsible rotate collapsed'
                          data-bs-toggle='collapse'
                          // href='#kt_customer_view_payment_method_1'
                          role='button'
                          aria-expanded='false'
                          aria-controls='kt_customer_view_payment_method_1'
                        >
                          <div className='me-3 rotate-90'>
                            <i className='ki-duotone ki-right fs-3' />
                          </div>
                          <img
                            src='/metronic8/demo8/assets/media/svg/card-logos/mastercard.svg'
                            className='w-40px me-3'
                            alt=''
                          />
                          <div className='me-3'>
                            <div className='d-flex align-items-center'>
                              <div className='text-gray-800 '>Mastercard </div>
                              <div className='badge badge-light-primary ms-5'>Primary</div>
                            </div>
                            <div className='text-muted'>Expires Dec 2024</div>
                          </div>
                        </div>
                        <div className='d-flex my-3 ms-9'>
                          <a
                            href='#'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_new_card'
                          >
                            <span
                              data-bs-toggle='tooltip'
                              data-bs-trigger='hover'
                              aria-label='Edit'
                              data-bs-original-title='Edit'
                              data-kt-initialized={1}
                            >
                              <i className='ki-duotone ki-pencil fs-3'>
                                <span className='path1' />
                                <span className='path2' />
                              </i>
                            </span>
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                            data-bs-toggle='tooltip'
                            data-kt-customer-payment-method='delete'
                            aria-label='Delete'
                            data-bs-original-title='Delete'
                            data-kt-initialized={1}
                          >
                            <i className='ki-duotone ki-trash fs-3'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                              <span className='path5' />
                            </i>
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px'
                            data-bs-toggle='tooltip'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            aria-label='More Options'
                            data-bs-original-title='More Options'
                            data-kt-initialized={1}
                          >
                            <i className='ki-duotone ki-setting-3 fs-3'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                              <span className='path5' />
                            </i>
                          </a>
                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px py-3'
                            data-kt-menu='true'
                          >
                            <div className='menu-item px-3'>
                              <a
                                href='#'
                                className='menu-link px-3'
                                data-kt-payment-mehtod-action='set_as_primary'
                              >
                                Set as Primary
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id='kt_customer_view_payment_method_1'
                        className='fs-6 ps-10 collapse'
                        data-bs-parent='#kt_customer_view_payment_method'
                        style={{}}
                      >
                        <div className='d-flex flex-wrap py-5'>
                          <div className='flex-equal me-5'>
                            <table className='table table-flush fw-semibold gy-1'>
                              <tbody>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Name</td>
                                  <td className='text-gray-800'>Emma Smith</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Number</td>
                                  <td className='text-gray-800'>**** 4941</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Expires</td>
                                  <td className='text-gray-800'>12/2024</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Type</td>
                                  <td className='text-gray-800'>Mastercard credit card</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Issuer</td>
                                  <td className='text-gray-800'>VICBANK</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>ID</td>
                                  <td className='text-gray-800'>id_4325df90sdf8</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='flex-equal '>
                            <table className='table table-flush fw-semibold gy-1'>
                              <tbody>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>
                                    Billing address
                                  </td>
                                  <td className='text-gray-800'>AU</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Phone</td>
                                  <td className='text-gray-800'>No phone provided</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Email</td>
                                  <td className='text-gray-800'>
                                    <a href='#' className='text-gray-900 text-hover-primary'>
                                      smith@kpmg.com
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Origin</td>
                                  <td className='text-gray-800'>
                                    Australia
                                    <div className='symbol symbol-20px symbol-circle ms-2'>
                                      <img src='/metronic8/demo8/assets/media/flags/australia.svg' />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>CVC check</td>
                                  <td className='text-gray-800'>
                                    Passed
                                    <i className='ki-duotone ki-check-circle fs-2 text-success'>
                                      <span className='path1' />
                                      <span className='path2' />
                                    </i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='separator separator-dashed' />
                    <div className='py-0' data-kt-customer-payment-method='row'>
                      <div className='py-3 d-flex flex-stack flex-wrap'>
                        <div
                          className='d-flex align-items-center collapsible rotate collapsed'
                          data-bs-toggle='collapse'
                          role='button'
                          aria-expanded='false'
                          aria-controls='kt_customer_view_payment_method_2'
                        >
                          <div className='me-3 rotate-90'>
                            <i className='ki-duotone ki-right fs-3' />
                          </div>
                          <img
                            src='/metronic8/demo8/assets/media/svg/card-logos/visa.svg'
                            className='w-40px me-3'
                            alt=''
                          />
                          <div className='me-3'>
                            <div className='d-flex align-items-center'>
                              <div className='text-gray-800 '>Visa </div>
                            </div>
                            <div className='text-muted'>Expires Feb 2022</div>
                          </div>
                        </div>
                        <div className='d-flex my-3 ms-9'>
                          <a
                            href='#'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_new_card'
                          >
                            <span
                              data-bs-toggle='tooltip'
                              data-bs-trigger='hover'
                              aria-label='Edit'
                              data-bs-original-title='Edit'
                              data-kt-initialized={1}
                            >
                              <i className='ki-duotone ki-pencil fs-3'>
                                <span className='path1' />
                                <span className='path2' />
                              </i>
                            </span>
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                            data-bs-toggle='tooltip'
                            data-kt-customer-payment-method='delete'
                            aria-label='Delete'
                            data-bs-original-title='Delete'
                            data-kt-initialized={1}
                          >
                            <i className='ki-duotone ki-trash fs-3'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                              <span className='path5' />
                            </i>
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px'
                            data-bs-toggle='tooltip'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            aria-label='More Options'
                            data-bs-original-title='More Options'
                            data-kt-initialized={1}
                          >
                            <i className='ki-duotone ki-setting-3 fs-3'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                              <span className='path5' />
                            </i>
                          </a>
                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px py-3'
                            data-kt-menu='true'
                          >
                            <div className='menu-item px-3'>
                              <a
                                href='#'
                                className='menu-link px-3'
                                data-kt-payment-mehtod-action='set_as_primary'
                              >
                                Set as Primary
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id='kt_customer_view_payment_method_2'
                        className='fs-6 ps-10 collapse'
                        data-bs-parent='#kt_customer_view_payment_method'
                        style={{}}
                      >
                        <div className='d-flex flex-wrap py-5'>
                          <div className='flex-equal me-5'>
                            <table className='table table-flush fw-semibold gy-1'>
                              <tbody>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Name</td>
                                  <td className='text-gray-800'>Melody Macy</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Number</td>
                                  <td className='text-gray-800'>**** 5292</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Expires</td>
                                  <td className='text-gray-800'>02/2022</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Type</td>
                                  <td className='text-gray-800'>Visa credit card</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Issuer</td>
                                  <td className='text-gray-800'>ENBANK</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>ID</td>
                                  <td className='text-gray-800'>id_w2r84jdy723</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='flex-equal '>
                            <table className='table table-flush fw-semibold gy-1'>
                              <tbody>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>
                                    Billing address
                                  </td>
                                  <td className='text-gray-800'>UK</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Phone</td>
                                  <td className='text-gray-800'>No phone provided</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Email</td>
                                  <td className='text-gray-800'>
                                    <a href='#' className='text-gray-900 text-hover-primary'>
                                      melody@altbox.com
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Origin</td>
                                  <td className='text-gray-800'>
                                    United Kingdom
                                    <div className='symbol symbol-20px symbol-circle ms-2'>
                                      <img src='/metronic8/demo8/assets/media/flags/united-kingdom.svg' />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>CVC check</td>
                                  <td className='text-gray-800'>
                                    Passed <i className='ki-duotone ki-check fs-2 text-success' />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='separator separator-dashed' />
                    <div className='py-0' data-kt-customer-payment-method='row'>
                      <div className='py-3 d-flex flex-stack flex-wrap'>
                        <div
                          className='d-flex align-items-center collapsible rotate collapsed'
                          data-bs-toggle='collapse'
                          // href='#kt_customer_view_payment_method_3'
                          role='button'
                          aria-expanded='false'
                          aria-controls='kt_customer_view_payment_method_3'
                        >
                          <div className='me-3 rotate-90'>
                            <i className='ki-duotone ki-right fs-3' />
                          </div>
                          <img
                            src='/metronic8/demo8/assets/media/svg/card-logos/american-express.svg'
                            className='w-40px me-3'
                            alt=''
                          />
                          <div className='me-3'>
                            <div className='d-flex align-items-center'>
                              <div className='text-gray-800 '>American Express </div>
                              <div className='badge badge-light-danger ms-5'>Expired</div>
                            </div>
                            <div className='text-muted'>Expires Aug 2021</div>
                          </div>
                        </div>
                        <div className='d-flex my-3 ms-9'>
                          <a
                            href='#'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_new_card'
                          >
                            <span
                              data-bs-toggle='tooltip'
                              data-bs-trigger='hover'
                              aria-label='Edit'
                              data-bs-original-title='Edit'
                              data-kt-initialized={1}
                            >
                              <i className='ki-duotone ki-pencil fs-3'>
                                <span className='path1' />
                                <span className='path2' />
                              </i>
                            </span>
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                            data-bs-toggle='tooltip'
                            data-kt-customer-payment-method='delete'
                            aria-label='Delete'
                            data-bs-original-title='Delete'
                            data-kt-initialized={1}
                          >
                            <i className='ki-duotone ki-trash fs-3'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                              <span className='path5' />
                            </i>
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-active-light-primary w-30px h-30px'
                            data-bs-toggle='tooltip'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            aria-label='More Options'
                            data-bs-original-title='More Options'
                            data-kt-initialized={1}
                          >
                            <i className='ki-duotone ki-setting-3 fs-3'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                              <span className='path5' />
                            </i>
                          </a>
                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px py-3'
                            data-kt-menu='true'
                          >
                            <div className='menu-item px-3'>
                              <a
                                href='#'
                                className='menu-link px-3'
                                data-kt-payment-mehtod-action='set_as_primary'
                              >
                                Set as Primary
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id='kt_customer_view_payment_method_3'
                        className='fs-6 ps-10 collapse'
                        data-bs-parent='#kt_customer_view_payment_method'
                        style={{}}
                      >
                        <div className='d-flex flex-wrap py-5'>
                          <div className='flex-equal me-5'>
                            <table className='table table-flush fw-semibold gy-1'>
                              <tbody>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Name</td>
                                  <td className='text-gray-800'>Max Smith</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Number</td>
                                  <td className='text-gray-800'>**** 2469</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Expires</td>
                                  <td className='text-gray-800'>08/2021</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Type</td>
                                  <td className='text-gray-800'>American express credit card</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Issuer</td>
                                  <td className='text-gray-800'>USABANK</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>ID</td>
                                  <td className='text-gray-800'>id_89457jcje63</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='flex-equal '>
                            <table className='table table-flush fw-semibold gy-1'>
                              <tbody>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>
                                    Billing address
                                  </td>
                                  <td className='text-gray-800'>US</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Phone</td>
                                  <td className='text-gray-800'>No phone provided</td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Email</td>
                                  <td className='text-gray-800'>
                                    <a href='#' className='text-gray-900 text-hover-primary'>
                                      max@kt.com
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>Origin</td>
                                  <td className='text-gray-800'>
                                    United States of America
                                    <div className='symbol symbol-20px symbol-circle ms-2'>
                                      <img src='/metronic8/demo8/assets/media/flags/united-states.svg' />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-muted min-w-125px w-125px'>CVC check</td>
                                  <td className='text-gray-800'>
                                    Failed
                                    <i className='ki-duotone ki-cross fs-2 text-danger'>
                                      <span className='path1' />
                                      <span className='path2' />
                                    </i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card pt-4 mb-6 mb-xl-9'>
                  <div className='card-header border-0'>
                    <div className='card-title'>
                      <h2 className=''>Credit Balance</h2>
                    </div>
                    <div className='card-toolbar'>
                      <a
                        href='#'
                        className='btn btn-sm btn-flex btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_adjust_balance'
                      >
                        <i className='ki-duotone ki-pencil fs-3'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>
                        Adjust Balance
                      </a>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    <div className=' fs-2'>
                      $32,487.57 <span className='text-muted fs-4 fw-semibold'>USD</span>
                      <div className='fs-7 fw-normal text-muted'>
                        Balance will increase the amount due on the customer's next invoice.
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card pt-2 mb-6 mb-xl-9'>
                  <div className='card-header border-0'>
                    <div className='card-title'>
                      <h2>Invoices</h2>
                    </div>
                    <div className='card-toolbar m-0'>
                      <ul
                        className='nav nav-stretch fs-5 fw-semibold nav-line-tabs nav-line-tabs-2x border-transparent'
                        role='tablist'
                      >
                        <li className='nav-item' role='presentation'>
                          <a
                            id='kt_referrals_year_tab'
                            className='nav-link text-active-primary active'
                            data-bs-toggle='tab'
                            role='tab'
                            href='#kt_customer_details_invoices_1'
                            aria-selected='true'
                          >
                            This Year
                          </a>
                        </li>
                        <li className='nav-item' role='presentation'>
                          <a
                            id='kt_referrals_2019_tab'
                            className='nav-link text-active-primary ms-3'
                            data-bs-toggle='tab'
                            role='tab'
                            href='#kt_customer_details_invoices_2'
                            aria-selected='false'
                            tabIndex={-1}
                          >
                            2020
                          </a>
                        </li>
                        <li className='nav-item' role='presentation'>
                          <a
                            id='kt_referrals_2018_tab'
                            className='nav-link text-active-primary ms-3'
                            data-bs-toggle='tab'
                            role='tab'
                            href='#kt_customer_details_invoices_3'
                            aria-selected='false'
                            tabIndex={-1}
                          >
                            2019
                          </a>
                        </li>
                        <li className='nav-item' role='presentation'>
                          <a
                            id='kt_referrals_2017_tab'
                            className='nav-link text-active-primary ms-3'
                            data-bs-toggle='tab'
                            role='tab'
                            href='#kt_customer_details_invoices_4'
                            aria-selected='false'
                            tabIndex={-1}
                          >
                            2018
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    <div id='kt_referred_users_tab_content' className='tab-content'>
                      <div
                        id='kt_customer_details_invoices_1'
                        className='py-0 tab-pane fade show active'
                        role='tabpanel'
                        aria-labelledby='kt_referrals_year_tab'
                      >
                        <div
                          id='kt_customer_details_invoices_table_1_wrapper'
                          className='dt-container dt-bootstrap5 dt-empty-footer'
                        >
                          <div id='' className='table-responsive'>
                            <table
                              id='kt_customer_details_invoices_table_1'
                              className='table align-middle table-row-dashed fs-6  gy-5 dataTable'
                              style={{width: 525}}
                            >
                              <colgroup>
                                <col style={{width: 100}} />
                                <col style={{width: 100}} />
                                <col style={{width: 100}} />
                                <col style={{width: 125}} />
                                <col style={{width: 100}} />
                              </colgroup>
                              <thead className='border-bottom border-gray-200 fs-7 text-uppercase '>
                                <tr className='text-start text-muted gs-0' role='row'>
                                  <th
                                    className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={0}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Order ID: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Order ID
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={1}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Amount: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Amount
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={2}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Status: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Status
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-125px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={3}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Date: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Date
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px text-end pe-7 dt-orderable-none'
                                    data-dt-column={4}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Invoice'
                                  >
                                    <span className='dt-column-title'>Invoice</span>
                                    <span className='dt-column-order' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='fs-6 fw-semibold text-gray-600'>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td>
                                    <span className='badge badge-light-success'>Approved</span>
                                  </td>
                                  <td>Nov 01, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      423445721
                                    </a>
                                  </td>
                                  <td className='text-danger dt-type-numeric'>$-2.60</td>
                                  <td>
                                    <span className='badge badge-light-warning'>Pending</span>
                                  </td>
                                  <td>Oct 24, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$76.00</td>
                                  <td>
                                    <span className='badge badge-light-warning'>Pending</span>
                                  </td>
                                  <td>Oct 08, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$5.00</td>
                                  <td>
                                    <span className='badge badge-light-danger'>Rejected</span>
                                  </td>
                                  <td>Sep 15, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      523445943
                                    </a>
                                  </td>
                                  <td className='text-danger dt-type-numeric'>$-1.30</td>
                                  <td>
                                    <span className='badge badge-light-warning'>Pending</span>
                                  </td>
                                  <td>May 30, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot />
                            </table>
                          </div>
                          <div id='' className='row'>
                            <div
                              id=''
                              className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                            />
                            <div
                              id=''
                              className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                            >
                              <div className='dt-paging paging_simple_numbers'>
                                <ul className='pagination'>
                                  <li className='dt-paging-button page-item disabled'>
                                    <a
                                      className='page-link previous'
                                      aria-controls='kt_customer_details_invoices_table_1'
                                      aria-disabled='true'
                                      aria-label='Previous'
                                      data-dt-idx='previous'
                                      tabIndex={-1}
                                    >
                                      <i className='previous' />
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item active'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_details_invoices_table_1'
                                      aria-current='page'
                                      data-dt-idx={0}
                                      tabIndex={0}
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_details_invoices_table_1'
                                      data-dt-idx={1}
                                      tabIndex={0}
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link next'
                                      aria-controls='kt_customer_details_invoices_table_1'
                                      aria-label='Next'
                                      data-dt-idx='next'
                                      tabIndex={0}
                                    >
                                      <i className='next' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id='kt_customer_details_invoices_2'
                        className='py-0 tab-pane fade '
                        role='tabpanel'
                        aria-labelledby='kt_referrals_2019_tab'
                      >
                        <div
                          id='kt_customer_details_invoices_table_2_wrapper'
                          className='dt-container dt-bootstrap5 dt-empty-footer'
                        >
                          <div id='' className='table-responsive'>
                            <table
                              id='kt_customer_details_invoices_table_2'
                              className='table align-middle table-row-dashed fs-6  gy-5 dataTable'
                              style={{width: 0}}
                            >
                              <colgroup>
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                              </colgroup>
                              <thead className='border-bottom border-gray-200 fs-7 text-uppercase '>
                                <tr className='text-start text-muted gs-0' role='row'>
                                  <th
                                    className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={0}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Order ID: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Order ID
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={1}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Amount: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Amount
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={2}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Status: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Status
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-125px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={3}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Date: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Date
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px text-end pe-7 dt-orderable-none'
                                    data-dt-column={4}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Invoice'
                                  >
                                    <span className='dt-column-title'>Invoice</span>
                                    <span className='dt-column-order' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='fs-6 fw-semibold text-gray-600'>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      523445943
                                    </a>
                                  </td>
                                  <td className='text-danger dt-type-numeric'>$-1.30</td>
                                  <td>
                                    <span className='badge badge-light-info'>In progress</span>
                                  </td>
                                  <td>May 30, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      231445943
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$204.00</td>
                                  <td>
                                    <span className='badge badge-light-warning'>Pending</span>
                                  </td>
                                  <td>Apr 22, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      426445943
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$31.00</td>
                                  <td>
                                    <span className='badge badge-light-info'>In progress</span>
                                  </td>
                                  <td>Feb 09, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      984445943
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$52.00</td>
                                  <td>
                                    <span className='badge badge-light-warning'>Pending</span>
                                  </td>
                                  <td>Nov 01, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      324442313
                                    </a>
                                  </td>
                                  <td className='text-danger dt-type-numeric'>$-0.80</td>
                                  <td>
                                    <span className='badge badge-light-info'>In progress</span>
                                  </td>
                                  <td>Jan 04, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot />
                            </table>
                          </div>
                          <div id='' className='row'>
                            <div
                              id=''
                              className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                            />
                            <div
                              id=''
                              className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                            >
                              <div className='dt-paging paging_simple_numbers'>
                                <ul className='pagination'>
                                  <li className='dt-paging-button page-item disabled'>
                                    <a
                                      className='page-link previous'
                                      aria-controls='kt_customer_details_invoices_table_2'
                                      aria-disabled='true'
                                      aria-label='Previous'
                                      data-dt-idx='previous'
                                      tabIndex={-1}
                                    >
                                      <i className='previous' />
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item active'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_details_invoices_table_2'
                                      aria-current='page'
                                      data-dt-idx={0}
                                      tabIndex={0}
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_details_invoices_table_2'
                                      data-dt-idx={1}
                                      tabIndex={0}
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link next'
                                      aria-controls='kt_customer_details_invoices_table_2'
                                      aria-label='Next'
                                      data-dt-idx='next'
                                      tabIndex={0}
                                    >
                                      <i className='next' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id='kt_customer_details_invoices_3'
                        className='py-0 tab-pane fade '
                        role='tabpanel'
                        aria-labelledby='kt_referrals_2018_tab'
                      >
                        <div
                          id='kt_customer_details_invoices_table_3_wrapper'
                          className='dt-container dt-bootstrap5 dt-empty-footer'
                        >
                          <div id='' className='table-responsive'>
                            <table
                              id='kt_customer_details_invoices_table_3'
                              className='table align-middle table-row-dashed fs-6  gy-5 dataTable'
                              style={{width: 0}}
                            >
                              <colgroup>
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                              </colgroup>
                              <thead className='border-bottom border-gray-200 fs-7 text-uppercase '>
                                <tr className='text-start text-muted gs-0' role='row'>
                                  <th
                                    className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={0}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Order ID: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Order ID
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={1}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Amount: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Amount
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={2}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Status: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Status
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-125px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={3}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Date: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Date
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px text-end pe-7 dt-orderable-none'
                                    data-dt-column={4}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Invoice'
                                  >
                                    <span className='dt-column-title'>Invoice</span>
                                    <span className='dt-column-order' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='fs-6 fw-semibold text-gray-600'>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      426445943
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$31.00</td>
                                  <td>
                                    <span className='badge badge-light-success'>Approved</span>
                                  </td>
                                  <td>Feb 09, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      984445943
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$52.00</td>
                                  <td>
                                    <span className='badge badge-light-danger'>Rejected</span>
                                  </td>
                                  <td>Nov 01, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      324442313
                                    </a>
                                  </td>
                                  <td className='text-danger dt-type-numeric'>$-0.80</td>
                                  <td>
                                    <span className='badge badge-light-success'>Approved</span>
                                  </td>
                                  <td>Jan 04, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$5.00</td>
                                  <td>
                                    <span className='badge badge-light-danger'>Rejected</span>
                                  </td>
                                  <td>Sep 15, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td>
                                    <span className='badge badge-light-warning'>Pending</span>
                                  </td>
                                  <td>Nov 01, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot />
                            </table>
                          </div>
                          <div id='' className='row'>
                            <div
                              id=''
                              className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                            />
                            <div
                              id=''
                              className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                            >
                              <div className='dt-paging paging_simple_numbers'>
                                <ul className='pagination'>
                                  <li className='dt-paging-button page-item disabled'>
                                    <a
                                      className='page-link previous'
                                      aria-controls='kt_customer_details_invoices_table_3'
                                      aria-disabled='true'
                                      aria-label='Previous'
                                      data-dt-idx='previous'
                                      tabIndex={-1}
                                    >
                                      <i className='previous' />
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item active'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_details_invoices_table_3'
                                      aria-current='page'
                                      data-dt-idx={0}
                                      tabIndex={0}
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_details_invoices_table_3'
                                      data-dt-idx={1}
                                      tabIndex={0}
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link next'
                                      aria-controls='kt_customer_details_invoices_table_3'
                                      aria-label='Next'
                                      data-dt-idx='next'
                                      tabIndex={0}
                                    >
                                      <i className='next' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id='kt_customer_details_invoices_4'
                        className='py-0 tab-pane fade '
                        role='tabpanel'
                        aria-labelledby='kt_referrals_2017_tab'
                      >
                        <div
                          id='kt_customer_details_invoices_table_4_wrapper'
                          className='dt-container dt-bootstrap5 dt-empty-footer'
                        >
                          <div id='' className='table-responsive'>
                            <table
                              id='kt_customer_details_invoices_table_4'
                              className='table align-middle table-row-dashed fs-6  gy-5 dataTable'
                              style={{width: 0}}
                            >
                              <colgroup>
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                              </colgroup>
                              <thead className='border-bottom border-gray-200 fs-7 text-uppercase '>
                                <tr className='text-start text-muted gs-0' role='row'>
                                  <th
                                    className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={0}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Order ID: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Order ID
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={1}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Amount: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Amount
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={2}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Status: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Status
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-125px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={3}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Date: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Date
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='min-w-100px text-end pe-7 dt-orderable-none'
                                    data-dt-column={4}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Invoice'
                                  >
                                    <span className='dt-column-title'>Invoice</span>
                                    <span className='dt-column-order' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='fs-6 fw-semibold text-gray-600'>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td>
                                    <span className='badge badge-light-info'>In progress</span>
                                  </td>
                                  <td>Nov 01, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      423445721
                                    </a>
                                  </td>
                                  <td className='text-danger dt-type-numeric'>$-2.60</td>
                                  <td>
                                    <span className='badge badge-light-info'>In progress</span>
                                  </td>
                                  <td>Oct 24, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td>
                                    <span className='badge badge-light-warning'>Pending</span>
                                  </td>
                                  <td>Nov 01, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      423445721
                                    </a>
                                  </td>
                                  <td className='text-danger dt-type-numeric'>$-2.60</td>
                                  <td>
                                    <span className='badge badge-light-success'>Approved</span>
                                  </td>
                                  <td>Oct 24, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='Invalid date'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      426445943
                                    </a>
                                  </td>
                                  <td className='text-success dt-type-numeric'>$31.00</td>
                                  <td>
                                    <span className='badge badge-light-warning'>Pending</span>
                                  </td>
                                  <td>Feb 09, 2020</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot />
                            </table>
                          </div>
                          <div id='' className='row'>
                            <div
                              id=''
                              className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                            />
                            <div
                              id=''
                              className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                            >
                              <div className='dt-paging paging_simple_numbers'>
                                <ul className='pagination'>
                                  <li className='dt-paging-button page-item disabled'>
                                    <a
                                      className='page-link previous'
                                      aria-controls='kt_customer_details_invoices_table_4'
                                      aria-disabled='true'
                                      aria-label='Previous'
                                      data-dt-idx='previous'
                                      tabIndex={-1}
                                    >
                                      <i className='previous' />
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item active'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_details_invoices_table_4'
                                      aria-current='page'
                                      data-dt-idx={0}
                                      tabIndex={0}
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_details_invoices_table_4'
                                      data-dt-idx={1}
                                      tabIndex={0}
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link next'
                                      aria-controls='kt_customer_details_invoices_table_4'
                                      aria-label='Next'
                                      data-dt-idx='next'
                                      tabIndex={0}
                                    >
                                      <i className='next' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='kt_customer_view_overview_events_and_logs_tab'
                role='tabpanel'
              >
                <div className='card pt-4 mb-6 mb-xl-9'>
                  <div className='card-header border-0'>
                    <div className='card-title'>
                      <h2>Logs</h2>
                    </div>
                    <div className='card-toolbar'>
                      <button type='button' className='btn btn-sm btn-light-primary'>
                        <i className='ki-duotone ki-cloud-download fs-3'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>
                        Download Report
                      </button>
                    </div>
                  </div>
                  <div className='card-body py-0'>
                    <div className='table-responsive'>
                      <table
                        className='table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5'
                        id='kt_table_customers_logs'
                      >
                        <tbody>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-success'>200 OK</div>
                            </td>
                            <td>POST /v1/invoices/in_7182_9217/payment </td>
                            <td className='pe-0 text-end min-w-200px'>25 Oct 2024, 5:20 pm </td>
                          </tr>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-warning'>404 WRN</div>
                            </td>
                            <td>POST /v1/customer/c_661e769145cc0/not_found </td>
                            <td className='pe-0 text-end min-w-200px'>25 Oct 2024, 2:40 pm </td>
                          </tr>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-success'>200 OK</div>
                            </td>
                            <td>POST /v1/invoices/in_8451_7889/payment </td>
                            <td className='pe-0 text-end min-w-200px'>10 Mar 2024, 5:30 pm </td>
                          </tr>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-danger'>500 ERR</div>
                            </td>
                            <td>POST /v1/invoice/in_9232_4963/invalid </td>
                            <td className='pe-0 text-end min-w-200px'>20 Jun 2024, 11:30 am </td>
                          </tr>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-success'>200 OK</div>
                            </td>
                            <td>POST /v1/invoices/in_8451_7889/payment </td>
                            <td className='pe-0 text-end min-w-200px'>15 Apr 2024, 5:30 pm </td>
                          </tr>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-success'>200 OK</div>
                            </td>
                            <td>POST /v1/invoices/in_5023_9734/payment </td>
                            <td className='pe-0 text-end min-w-200px'>19 Aug 2024, 5:30 pm </td>
                          </tr>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-success'>200 OK</div>
                            </td>
                            <td>POST /v1/invoices/in_8726_5076/payment </td>
                            <td className='pe-0 text-end min-w-200px'>24 Jun 2024, 10:30 am </td>
                          </tr>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-danger'>500 ERR</div>
                            </td>
                            <td>POST /v1/invoice/in_9232_4963/invalid </td>
                            <td className='pe-0 text-end min-w-200px'>10 Mar 2024, 8:43 pm </td>
                          </tr>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-danger'>500 ERR</div>
                            </td>
                            <td>POST /v1/invoice/in_7967_7232/invalid </td>
                            <td className='pe-0 text-end min-w-200px'>10 Mar 2024, 2:40 pm </td>
                          </tr>
                          <tr>
                            <td className='min-w-70px'>
                              <div className='badge badge-light-warning'>404 WRN</div>
                            </td>
                            <td>POST /v1/customer/c_661e769145cc0/not_found </td>
                            <td className='pe-0 text-end min-w-200px'>22 Sep 2024, 6:43 am </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='card pt-4 mb-6 mb-xl-9'>
                  <div className='card-header border-0'>
                    <div className='card-title'>
                      <h2>Events</h2>
                    </div>
                    <div className='card-toolbar'>
                      <button type='button' className='btn btn-sm btn-light-primary'>
                        <i className='ki-duotone ki-cloud-download fs-3'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>
                        Download Report
                      </button>
                    </div>
                  </div>
                  <div className='card-body py-0'>
                    <table
                      className='table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-5'
                      id='kt_table_customers_events'
                    >
                      <tbody>
                        <tr>
                          <td className='min-w-400px'>
                            Invoice
                            <a href='#' className=' text-gray-900 text-hover-primary me-1'>
                              #SEP-45656
                            </a>
                            status has changed from
                            <span className='badge badge-light-warning me-1'>Pending</span> to
                            <span className='badge badge-light-info'>In Progress</span>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            22 Sep 2024, 2:40 pm
                          </td>
                        </tr>
                        <tr>
                          <td className='min-w-400px'>
                            Invoice
                            <a href='#' className=' text-gray-900 text-hover-primary me-1'>
                              #LOP-45640
                            </a>
                            has been <span className='badge badge-light-danger'>Declined</span>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            05 May 2024, 2:40 pm
                          </td>
                        </tr>
                        <tr>
                          <td className='min-w-400px'>
                            <a href='#' className='text-gray-600 text-hover-primary me-1'>
                              Sean Bean
                            </a>
                            has made payment to
                            <a href='#' className=' text-gray-900 text-hover-primary'>
                              #XRS-45670
                            </a>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            21 Feb 2024, 2:40 pm
                          </td>
                        </tr>
                        <tr>
                          <td className='min-w-400px'>
                            Invoice
                            <a href='#' className=' text-gray-900 text-hover-primary me-1'>
                              #KIO-45656
                            </a>
                            status has changed from
                            <span className='badge badge-light-succees me-1'>In Transit</span> to
                            <span className='badge badge-light-success'>Approved</span>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            19 Aug 2024, 10:30 am
                          </td>
                        </tr>
                        <tr>
                          <td className='min-w-400px'>
                            Invoice
                            <a href='#' className=' text-gray-900 text-hover-primary me-1'>
                              #KIO-45656
                            </a>
                            status has changed from
                            <span className='badge badge-light-succees me-1'>In Transit</span> to
                            <span className='badge badge-light-success'>Approved</span>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            20 Dec 2024, 9:23 pm
                          </td>
                        </tr>
                        <tr>
                          <td className='min-w-400px'>
                            Invoice
                            <a href='#' className=' text-gray-900 text-hover-primary me-1'>
                              #DER-45645
                            </a>
                            status has changed from
                            <span className='badge badge-light-info me-1'>In Progress</span> to
                            <span className='badge badge-light-primary'>In Transit</span>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            15 Apr 2024, 6:43 am
                          </td>
                        </tr>
                        <tr>
                          <td className='min-w-400px'>
                            Invoice
                            <a href='#' className=' text-gray-900 text-hover-primary me-1'>
                              #DER-45645
                            </a>
                            status has changed from
                            <span className='badge badge-light-info me-1'>In Progress</span> to
                            <span className='badge badge-light-primary'>In Transit</span>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            20 Jun 2024, 8:43 pm
                          </td>
                        </tr>
                        <tr>
                          <td className='min-w-400px'>
                            <a href='#' className='text-gray-600 text-hover-primary me-1'>
                              Brian Cox
                            </a>
                            has made payment to
                            <a href='#' className=' text-gray-900 text-hover-primary'>
                              #OLP-45690
                            </a>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            24 Jun 2024, 11:05 am
                          </td>
                        </tr>
                        <tr>
                          <td className='min-w-400px'>
                            Invoice
                            <a href='#' className=' text-gray-900 text-hover-primary me-1'>
                              #WER-45670
                            </a>
                            is <span className='badge badge-light-info'>In Progress</span>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            20 Jun 2024, 11:05 am
                          </td>
                        </tr>
                        <tr>
                          <td className='min-w-400px'>
                            Invoice
                            <a href='#' className=' text-gray-900 text-hover-primary me-1'>
                              #LOP-45640
                            </a>
                            has been <span className='badge badge-light-danger'>Declined</span>
                          </td>
                          <td className='pe-0 text-gray-600 text-end min-w-200px'>
                            22 Sep 2024, 10:10 pm
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='kt_customer_view_overview_statements'
                role='tabpanel'
              >
                <div className='card mb-6 mb-xl-9'>
                  <div className='card-header border-0'>
                    <div className='card-title'>
                      <h2>Earnings</h2>
                    </div>
                  </div>
                  <div className='card-body py-0'>
                    <div className='fs-5 fw-semibold text-gray-500 mb-4'>
                      Last 30 day earnings calculated. Apart from arranging the order of topics.
                    </div>
                    <div className='d-flex flex-wrap flex-stack mb-5'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-dashed border-gray-300 w-150px rounded my-3 p-4 me-6'>
                          <span className='fs-1  text-gray-800 lh-1'>
                            <span
                              data-kt-countup='true'
                              data-kt-countup-value='6,840'
                              data-kt-countup-prefix='$'
                              className='counted'
                              data-kt-initialized={1}
                            >
                              $6,840
                            </span>
                            <i className='ki-duotone ki-arrow-up fs-1 text-success'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>
                          </span>
                          <span className='fs-6 fw-semibold text-muted d-block lh-1 pt-2'>
                            Net Earnings
                          </span>
                        </div>
                        <div className='border border-dashed border-gray-300 w-125px rounded my-3 p-4 me-6'>
                          <span className='fs-1  text-gray-800 lh-1'>
                            <span
                              className='counted'
                              data-kt-countup='true'
                              data-kt-countup-value={16}
                              data-kt-initialized={1}
                            >
                              16
                            </span>
                            %
                            <i className='ki-duotone ki-arrow-down fs-1 text-danger'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>
                          </span>
                          <span className='fs-6 fw-semibold text-muted d-block lh-1 pt-2'>
                            Change
                          </span>
                        </div>
                        <div className='border border-dashed border-gray-300 w-150px rounded my-3 p-4 me-6'>
                          <span className='fs-1  text-gray-800 lh-1'>
                            <span
                              data-kt-countup='true'
                              data-kt-countup-value='1,240'
                              data-kt-countup-prefix='$'
                              className='counted'
                              data-kt-initialized={1}
                            >
                              $1,240
                            </span>
                            <span className='text-primary'>--</span>
                          </span>
                          <span className='fs-6 fw-semibold text-muted d-block lh-1 pt-2'>
                            Fees
                          </span>
                        </div>
                      </div>
                      <a href='#' className='btn btn-sm btn-light-primary flex-shrink-0'>
                        Withdraw Earnings
                      </a>
                    </div>
                  </div>
                </div>
                <div className='card mb-6 mb-xl-9'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2>Statement</h2>
                    </div>
                    <div className='card-toolbar'>
                      <ul
                        className='nav nav-stretch fs-5 fw-semibold nav-line-tabs nav-line-tabs-2x border-transparent'
                        role='tablist'
                      >
                        <li className='nav-item' role='presentation'>
                          <a
                            className='nav-link text-active-primary active'
                            data-bs-toggle='tab'
                            role='tab'
                            href='#kt_customer_view_statement_1'
                            aria-selected='true'
                          >
                            This Year
                          </a>
                        </li>
                        <li className='nav-item' role='presentation'>
                          <a
                            className='nav-link text-active-primary ms-3'
                            data-bs-toggle='tab'
                            role='tab'
                            href='#kt_customer_view_statement_2'
                            aria-selected='false'
                            tabIndex={-1}
                          >
                            2020
                          </a>
                        </li>
                        <li className='nav-item' role='presentation'>
                          <a
                            className='nav-link text-active-primary ms-3'
                            data-bs-toggle='tab'
                            role='tab'
                            href='#kt_customer_view_statement_3'
                            aria-selected='false'
                            tabIndex={-1}
                          >
                            2019
                          </a>
                        </li>
                        <li className='nav-item' role='presentation'>
                          <a
                            className='nav-link text-active-primary ms-3'
                            data-bs-toggle='tab'
                            role='tab'
                            href='#kt_customer_view_statement_4'
                            aria-selected='false'
                            tabIndex={-1}
                          >
                            2018
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='card-body pb-5'>
                    <div id='kt_customer_view_statement_tab_content' className='tab-content'>
                      <div
                        id='kt_customer_view_statement_1'
                        className='py-0 tab-pane fade show active'
                        role='tabpanel'
                      >
                        <div
                          id='kt_customer_view_statement_table_1_wrapper'
                          className='dt-container dt-bootstrap5 dt-empty-footer'
                        >
                          <div id='' className='table-responsive'>
                            <table
                              id='kt_customer_view_statement_table_1'
                              className='table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-4 dataTable'
                              style={{width: 0}}
                            >
                              <colgroup>
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                              </colgroup>
                              <thead className='border-bottom border-gray-200'>
                                <tr
                                  className='text-start text-muted  fs-7 text-uppercase gs-0'
                                  role='row'
                                >
                                  <th
                                    className='w-125px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={0}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Date: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Date
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={1}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Order ID: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Order ID
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-300px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={2}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Details: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Details
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={3}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Amount: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Amount
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px text-end pe-7 dt-orderable-none'
                                    data-dt-column={4}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Invoice'
                                  >
                                    <span className='dt-column-title'>Invoice</span>
                                    <span className='dt-column-order' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td data-order='2021-01-01T00:00:00+05:00'>Nov 01, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td>Darknight transparency 36 Icons Pack</td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2021-01-24T00:00:00+05:00'>Oct 24, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      423445721
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-2.60</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2021-01-08T00:00:00+05:00'>Oct 08, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td>Cartoon Mobile Emoji Phone Pack</td>
                                  <td className='text-success dt-type-numeric'>$76.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2021-01-15T00:00:00+05:00'>Sep 15, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td>Iphone 12 Pro Mockup Mega Bundle</td>
                                  <td className='text-success dt-type-numeric'>$5.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2021-01-30T00:00:00+05:00'>May 30, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      523445943
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-1.30</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2021-01-22T00:00:00+05:00'>Apr 22, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      231445943
                                    </a>
                                  </td>
                                  <td>Parcel Shipping / Delivery Service App</td>
                                  <td className='text-success dt-type-numeric'>$204.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2021-01-09T00:00:00+05:00'>Feb 09, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      426445943
                                    </a>
                                  </td>
                                  <td>Visual Design Illustration</td>
                                  <td className='text-success dt-type-numeric'>$31.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2021-01-01T00:00:00+05:00'>Nov 01, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      984445943
                                    </a>
                                  </td>
                                  <td>Abstract Vusial Pack</td>
                                  <td className='text-success dt-type-numeric'>$52.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2021-01-04T00:00:00+05:00'>Jan 04, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      324442313
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-0.80</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2021-01-01T00:00:00+05:00'>Nov 01, 2021</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td>Darknight transparency 36 Icons Pack</td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot />
                            </table>
                          </div>
                          <div id='' className='row'>
                            <div
                              id=''
                              className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                            />
                            <div
                              id=''
                              className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                            >
                              <div className='dt-paging paging_simple_numbers'>
                                <ul className='pagination'>
                                  <li className='dt-paging-button page-item disabled'>
                                    <a
                                      className='page-link previous'
                                      aria-controls='kt_customer_view_statement_table_1'
                                      aria-disabled='true'
                                      aria-label='Previous'
                                      data-dt-idx='previous'
                                      tabIndex={-1}
                                    >
                                      <i className='previous' />
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item active'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_view_statement_table_1'
                                      aria-current='page'
                                      data-dt-idx={0}
                                      tabIndex={0}
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_view_statement_table_1'
                                      data-dt-idx={1}
                                      tabIndex={0}
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link next'
                                      aria-controls='kt_customer_view_statement_table_1'
                                      aria-label='Next'
                                      data-dt-idx='next'
                                      tabIndex={0}
                                    >
                                      <i className='next' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id='kt_customer_view_statement_2'
                        className='py-0 tab-pane fade '
                        role='tabpanel'
                      >
                        <div
                          id='kt_customer_view_statement_table_2_wrapper'
                          className='dt-container dt-bootstrap5 dt-empty-footer'
                        >
                          <div id='' className='table-responsive'>
                            <table
                              id='kt_customer_view_statement_table_2'
                              className='table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-4 dataTable'
                              style={{width: 0}}
                            >
                              <colgroup>
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                              </colgroup>
                              <thead className='border-bottom border-gray-200'>
                                <tr
                                  className='text-start text-muted  fs-7 text-uppercase gs-0'
                                  role='row'
                                >
                                  <th
                                    className='w-125px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={0}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Date: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Date
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={1}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Order ID: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Order ID
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-300px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={2}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Details: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Details
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={3}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Amount: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Amount
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px text-end pe-7 dt-orderable-none'
                                    data-dt-column={4}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Invoice'
                                  >
                                    <span className='dt-column-title'>Invoice</span>
                                    <span className='dt-column-order' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td data-order='2020-01-30T00:00:00+05:00'>May 30, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      523445943
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-1.30</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2020-01-22T00:00:00+05:00'>Apr 22, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      231445943
                                    </a>
                                  </td>
                                  <td>Parcel Shipping / Delivery Service App</td>
                                  <td className='text-success dt-type-numeric'>$204.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2020-01-09T00:00:00+05:00'>Feb 09, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      426445943
                                    </a>
                                  </td>
                                  <td>Visual Design Illustration</td>
                                  <td className='text-success dt-type-numeric'>$31.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2020-01-01T00:00:00+05:00'>Nov 01, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      984445943
                                    </a>
                                  </td>
                                  <td>Abstract Vusial Pack</td>
                                  <td className='text-success dt-type-numeric'>$52.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2020-01-04T00:00:00+05:00'>Jan 04, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      324442313
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-0.80</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2020-01-01T00:00:00+05:00'>Nov 01, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td>Darknight transparency 36 Icons Pack</td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2020-01-24T00:00:00+05:00'>Oct 24, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      423445721
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-2.60</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2020-01-08T00:00:00+05:00'>Oct 08, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td>Cartoon Mobile Emoji Phone Pack</td>
                                  <td className='text-success dt-type-numeric'>$76.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2020-01-15T00:00:00+05:00'>Sep 15, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td>Iphone 12 Pro Mockup Mega Bundle</td>
                                  <td className='text-success dt-type-numeric'>$5.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2020-01-30T00:00:00+05:00'>May 30, 2020</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      523445943
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-1.30</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot />
                            </table>
                          </div>
                          <div id='' className='row'>
                            <div
                              id=''
                              className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                            />
                            <div
                              id=''
                              className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                            >
                              <div className='dt-paging paging_simple_numbers'>
                                <ul className='pagination'>
                                  <li className='dt-paging-button page-item disabled'>
                                    <a
                                      className='page-link previous'
                                      aria-controls='kt_customer_view_statement_table_2'
                                      aria-disabled='true'
                                      aria-label='Previous'
                                      data-dt-idx='previous'
                                      tabIndex={-1}
                                    >
                                      <i className='previous' />
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item active'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_view_statement_table_2'
                                      aria-current='page'
                                      data-dt-idx={0}
                                      tabIndex={0}
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_view_statement_table_2'
                                      data-dt-idx={1}
                                      tabIndex={0}
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link next'
                                      aria-controls='kt_customer_view_statement_table_2'
                                      aria-label='Next'
                                      data-dt-idx='next'
                                      tabIndex={0}
                                    >
                                      <i className='next' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id='kt_customer_view_statement_3'
                        className='py-0 tab-pane fade '
                        role='tabpanel'
                      >
                        <div
                          id='kt_customer_view_statement_table_3_wrapper'
                          className='dt-container dt-bootstrap5 dt-empty-footer'
                        >
                          <div id='' className='table-responsive'>
                            <table
                              id='kt_customer_view_statement_table_3'
                              className='table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-4 dataTable'
                              style={{width: 0}}
                            >
                              <colgroup>
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                              </colgroup>
                              <thead className='border-bottom border-gray-200'>
                                <tr
                                  className='text-start text-muted  fs-7 text-uppercase gs-0'
                                  role='row'
                                >
                                  <th
                                    className='w-125px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={0}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Date: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Date
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={1}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Order ID: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Order ID
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-300px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={2}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Details: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Details
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={3}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Amount: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Amount
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px text-end pe-7 dt-orderable-none'
                                    data-dt-column={4}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Invoice'
                                  >
                                    <span className='dt-column-title'>Invoice</span>
                                    <span className='dt-column-order' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td data-order='2019-01-09T00:00:00+05:00'>Feb 09, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      426445943
                                    </a>
                                  </td>
                                  <td>Visual Design Illustration</td>
                                  <td className='text-success dt-type-numeric'>$31.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-01T00:00:00+05:00'>Nov 01, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      984445943
                                    </a>
                                  </td>
                                  <td>Abstract Vusial Pack</td>
                                  <td className='text-success dt-type-numeric'>$52.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-04T00:00:00+05:00'>Jan 04, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      324442313
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-0.80</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-15T00:00:00+05:00'>Sep 15, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td>Iphone 12 Pro Mockup Mega Bundle</td>
                                  <td className='text-success dt-type-numeric'>$5.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-01T00:00:00+05:00'>Nov 01, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td>Darknight transparency 36 Icons Pack</td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-24T00:00:00+05:00'>Oct 24, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      423445721
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-2.60</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-08T00:00:00+05:00'>Oct 08, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td>Cartoon Mobile Emoji Phone Pack</td>
                                  <td className='text-success dt-type-numeric'>$76.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-30T00:00:00+05:00'>May 30, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      523445943
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-1.30</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-22T00:00:00+05:00'>Apr 22, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      231445943
                                    </a>
                                  </td>
                                  <td>Parcel Shipping / Delivery Service App</td>
                                  <td className='text-success dt-type-numeric'>$204.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-09T00:00:00+05:00'>Feb 09, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      426445943
                                    </a>
                                  </td>
                                  <td>Visual Design Illustration</td>
                                  <td className='text-success dt-type-numeric'>$31.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot />
                            </table>
                          </div>
                          <div id='' className='row'>
                            <div
                              id=''
                              className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                            />
                            <div
                              id=''
                              className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                            >
                              <div className='dt-paging paging_simple_numbers'>
                                <ul className='pagination'>
                                  <li className='dt-paging-button page-item disabled'>
                                    <a
                                      className='page-link previous'
                                      aria-controls='kt_customer_view_statement_table_3'
                                      aria-disabled='true'
                                      aria-label='Previous'
                                      data-dt-idx='previous'
                                      tabIndex={-1}
                                    >
                                      <i className='previous' />
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item active'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_view_statement_table_3'
                                      aria-current='page'
                                      data-dt-idx={0}
                                      tabIndex={0}
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_view_statement_table_3'
                                      data-dt-idx={1}
                                      tabIndex={0}
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link next'
                                      aria-controls='kt_customer_view_statement_table_3'
                                      aria-label='Next'
                                      data-dt-idx='next'
                                      tabIndex={0}
                                    >
                                      <i className='next' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id='kt_customer_view_statement_4'
                        className='py-0 tab-pane fade '
                        role='tabpanel'
                      >
                        <div
                          id='kt_customer_view_statement_table_4_wrapper'
                          className='dt-container dt-bootstrap5 dt-empty-footer'
                        >
                          <div id='' className='table-responsive'>
                            <table
                              id='kt_customer_view_statement_table_4'
                              className='table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-4 dataTable'
                              style={{width: 0}}
                            >
                              <colgroup>
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                                <col style={{width: 0}} />
                              </colgroup>
                              <thead className='border-bottom border-gray-200'>
                                <tr
                                  className='text-start text-muted  fs-7 text-uppercase gs-0'
                                  role='row'
                                >
                                  <th
                                    className='w-125px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={0}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Date: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Date
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={1}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Order ID: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Order ID
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-300px dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={2}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Details: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Details
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc'
                                    data-dt-column={3}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Amount: Activate to sort'
                                    tabIndex={0}
                                  >
                                    <span className='dt-column-title' role='button'>
                                      Amount
                                    </span>
                                    <span className='dt-column-order' />
                                  </th>
                                  <th
                                    className='w-100px text-end pe-7 dt-orderable-none'
                                    data-dt-column={4}
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label='Invoice'
                                  >
                                    <span className='dt-column-title'>Invoice</span>
                                    <span className='dt-column-order' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td data-order='2018-01-01T00:00:00+05:00'>Nov 01, 2018</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td>Darknight transparency 36 Icons Pack</td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2018-01-24T00:00:00+05:00'>Oct 24, 2018</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      423445721
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-2.60</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2018-01-01T00:00:00+05:00'>Nov 01, 2018</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      102445788
                                    </a>
                                  </td>
                                  <td>Darknight transparency 36 Icons Pack</td>
                                  <td className='text-success dt-type-numeric'>$38.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2018-01-24T00:00:00+05:00'>Oct 24, 2018</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      423445721
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-2.60</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2018-01-09T00:00:00+05:00'>Feb 09, 2018</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      426445943
                                    </a>
                                  </td>
                                  <td>Visual Design Illustration</td>
                                  <td className='text-success dt-type-numeric'>$31.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2018-01-01T00:00:00+05:00'>Nov 01, 2018</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      984445943
                                    </a>
                                  </td>
                                  <td>Abstract Vusial Pack</td>
                                  <td className='text-success dt-type-numeric'>$52.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2018-01-04T00:00:00+05:00'>Jan 04, 2018</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      324442313
                                    </a>
                                  </td>
                                  <td>Seller Fee</td>
                                  <td className='text-danger dt-type-numeric'>$-0.80</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2018-01-08T00:00:00+05:00'>Oct 08, 2018</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td>Cartoon Mobile Emoji Phone Pack</td>
                                  <td className='text-success dt-type-numeric'>$76.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2018-01-08T00:00:00+05:00'>Oct 08, 2018</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      312445984
                                    </a>
                                  </td>
                                  <td>Cartoon Mobile Emoji Phone Pack</td>
                                  <td className='text-success dt-type-numeric'>$76.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td data-order='2019-01-09T00:00:00+05:00'>Feb 09, 2019</td>
                                  <td className='dt-type-numeric'>
                                    <a href='#' className='text-gray-600 text-hover-primary'>
                                      426445943
                                    </a>
                                  </td>
                                  <td>Visual Design Illustration</td>
                                  <td className='text-success dt-type-numeric'>$31.00</td>
                                  <td className='text-end'>
                                    <button className='btn btn-sm btn-light btn-active-light-primary'>
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot />
                            </table>
                          </div>
                          <div id='' className='row'>
                            <div
                              id=''
                              className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                            />
                            <div
                              id=''
                              className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                            >
                              <div className='dt-paging paging_simple_numbers'>
                                <ul className='pagination'>
                                  <li className='dt-paging-button page-item disabled'>
                                    <a
                                      className='page-link previous'
                                      aria-controls='kt_customer_view_statement_table_4'
                                      aria-disabled='true'
                                      aria-label='Previous'
                                      data-dt-idx='previous'
                                      tabIndex={-1}
                                    >
                                      <i className='previous' />
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item active'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_view_statement_table_4'
                                      aria-current='page'
                                      data-dt-idx={0}
                                      tabIndex={0}
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link'
                                      aria-controls='kt_customer_view_statement_table_4'
                                      data-dt-idx={1}
                                      tabIndex={0}
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className='dt-paging-button page-item'>
                                    <a
                                      href='#'
                                      className='page-link next'
                                      aria-controls='kt_customer_view_statement_table_4'
                                      aria-label='Next'
                                      data-dt-idx='next'
                                      tabIndex={0}
                                    >
                                      <i className='next' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
