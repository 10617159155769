import React from 'react'

const FooterCopyright = () => {
  return (
    <div className='text-muted order-2 order-md-1 fs-7'>
      <i className='fa-regular fa-copyright'></i> {new Date().getFullYear()}{' '}
      <a href='https://exord.uz/' target='_blank'>
        Exord Soft.
      </a>{' '}
      <span>Гарантия вашего успеха.</span>
      {/* <span className='text-muted  me-2'>{new Date().getFullYear()} &copy;</span> */}
      {/* <a href='https://exord.uz/' target='_blank' className='text-gray-800 text-hover-primary'>
        Made with <i className='fa-sharp fa-solid fa-heart' style={{color: '#d11717'}}></i> by Exord
        Soft
      </a> */}
    </div>
  )
}

export default FooterCopyright
