import React, {useState, forwardRef, useImperativeHandle} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import * as Yup from 'yup'
import clsx from 'clsx'

// @ts-ignore:
import Select from 'react-select'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG, selectStyles} from '../../../../_metronic/helpers'

import * as authHelper from '../../../modules/auth/core/AuthHelpers'
import {swal} from '../../../../_metronic/helpers/swal'
import {MAIN_STATUSES, swalDefaultConfig} from '../../../../_metronic/helpers/constants'

export const AddModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    role: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    username: Yup.string()
      .min(4, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 5}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    password: Yup.string()
      .min(5, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 5}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    phone: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    email: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    sip_login: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    sip_password: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    description: Yup.string().min(
      1,
      intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})
    ),
    status: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
  })

  let initialValues = {
    full_name: '',
    role: '',
    username: '',
    password: '',
    phone: '',
    email: '',
    description: '',
    status: '1',
    sip_login: '',
    sip_password: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)

      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.post('/users/', {
          description: values.description,
          email: values.email,
          name: values.full_name,
          password: values.password || null,
          phone: values.phone,
          role_id: Number(values.role),
          status: parseInt(values.status, 10),
          // @ts-ignore
          is_active: values.status == 1 ? true : false,
          username: values.username,
          region_id: null,
          sip_login: values.sip_login,
          sip_password: values.sip_password,
        })

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        return
      }
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  const getSelectContent = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2', {
            active: formik.values.status == value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.status == value.id}
            onChange={(e) => formik.setFieldValue('status', e.currentTarget.value)}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }

  return (
    <Modal className='es-modal' size='lg' show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.FULLNAME'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('full_name')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.full_name && formik.errors.full_name},
                {
                  'is-valid': formik.touched.full_name && !formik.errors.full_name,
                }
              )}
              value={formik.values.full_name}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.ROLE'})}</label>
            <Select
              // classNamePrefix='react-select'
              styles={selectStyles}
              placeholder=''
              components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
              className='react-select-styled react-select-solid'
              classNames={{
                control: () =>
                  formik.touched.role && !formik.errors.role
                    ? 'form-select p-0 border-success'
                    : formik.touched.role && formik.errors.role
                    ? 'form-select p-0 border-danger'
                    : 'form-select p-0',
              }}
              options={props.roles.map((x: any) => ({
                value: x.id,
                label: x.title,
              }))}
              onChange={(selectedItem: any) => {
                formik.setFieldValue('role', selectedItem.value)
              }}
              value={{
                value: (props.roles.find((x: any) => x.id == formik.values.role) || {}).id || null,
                label: (props.roles.find((x: any) => x.id == formik.values.role) || {}).title || '',
              }}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.LOGIN'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              value={formik.values.username}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.PASSWORD'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              value={formik.values.password}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.PHONE'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.phone && formik.errors.phone},
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
              value={formik.values.phone}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.EMAIL'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              value={formik.values.email}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>Sip login</label>
            <input
              type='text'
              {...formik.getFieldProps('sip_login')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.sip_login && formik.errors.sip_login},
                {
                  'is-valid': formik.touched.sip_login && !formik.errors.sip_login,
                }
              )}
              value={formik.values.sip_login}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>Sip password</label>
            <input
              type='text'
              {...formik.getFieldProps('sip_password')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.sip_password && formik.errors.sip_password},
                {
                  'is-valid': formik.touched.sip_password && !formik.errors.sip_password,
                }
              )}
              value={formik.values.sip_password}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.DESCRIPTION'})}</label>
            <textarea
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              value={formik.values.description}
              rows={4}
            />
          </div>
          <div className='col-md-6'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.STATUS'})}</label>
            <div className='form-check form-check-custom form-check-solid'>
              <div
                className='btn-group w-100'
                data-kt-buttons='true'
                data-kt-buttons-target='[data-kt-button]'
              >
                {getSelectContent(MAIN_STATUSES, 'patientStatus-')}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light btn-sm' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success btn-sm'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading == true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
