import React, {useState, useEffect, FC} from 'react'

interface TimeLeft {
  days: number | string
  hours: number | string
  minutes: number | string
  seconds: number | string
}

interface TimerProps {
  targetTime: string
}

const Timer: FC<TimerProps> = ({targetTime}) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(targetTime))
  // Function to calculate the time left
  function calculateTimeLeft(targetTime: string): TimeLeft {
    const targetDate = new Date(targetTime)
    const difference = new Date().getTime() - targetDate.getTime()

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    }

    const seconds =
      Math.floor((difference / 1000) % 60) > 9
        ? Math.floor((difference / 1000) % 60)
        : '0' + Math.floor((difference / 1000) % 60)
    const minutes =
      Math.floor((difference / (1000 * 60)) % 60) > 9
        ? Math.floor((difference / (1000 * 60)) % 60)
        : '0' + Math.floor((difference / (1000 * 60)) % 60)
    const hours =
      Math.floor((difference / (1000 * 60 * 60)) % 24) > 9
        ? Math.floor((difference / (1000 * 60 * 60)) % 24)
        : '0' + Math.floor((difference / (1000 * 60 * 60)) % 24)
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))

    return {
      days,
      hours,
      minutes,
      seconds,
    }
  }

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetTime))
    }, 1000)

    return () => clearInterval(timerInterval)
  }, [targetTime])

  return (
    <>
      {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
    </>
  )
}

export default Timer
