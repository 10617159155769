/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useRoleContext} from '../../../../app/context/getAccessRoleUser'

export function AsideMenuMain() {
  const intl = useIntl()
  const {findRoleUser, findRoleUserByArray} = useRoleContext()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {findRoleUserByArray([
        'statistics-location',
        'statistics-order-type',
        'statistics-payment',
        'statistics-products',
        'statistics-system',
        'statistics-terminals',
        'statistics-users',
        'statistics-courier',
        'statistics-abc',
        'statistics-xyz',
        'statistics-abcxyz',
        'statistics-rfm',
      ]) && (
        <AsideMenuItemWithSub
          to='/statistics'
          title={intl.formatMessage({id: 'MENU.STATS'})}
          icon='/media/icons/duotune/graphs/gra004.svg'
        >
          {findRoleUser('statistics-abc') && (
            <AsideMenuItem
              to='/statistics/abc'
              title={intl.formatMessage({id: 'MENU.ABC'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-abc') && (
            <AsideMenuItem
              to='/statistics/abc-clients'
              title={intl.formatMessage({id: 'MENU.ABC_CLIENTS'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-xyz') && (
            <AsideMenuItem
              to='/statistics/xyz'
              title={intl.formatMessage({id: 'MENU.XYZ'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-abcxyz') && (
            <AsideMenuItem
              to='/statistics/abcxyz'
              title={intl.formatMessage({id: 'MENU.ABCXYZ'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-rfm') && (
            <AsideMenuItem
              to='/statistics/rfm'
              title={intl.formatMessage({id: 'MENU.RFM'})}
              hasBullet={true}
            />
          )}
          {/* {findRoleUser('statistics-order-type') && (
            <AsideMenuItem
              to='/statistics/order-type'
              title={intl.formatMessage({id: 'MENU.CLIENTS'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-order-type') && (
            <AsideMenuItem
              to='/statistics/order-type'
              title={intl.formatMessage({id: 'MENU.TOP10'})}
              hasBullet={true}
            />
          )} */}

          {findRoleUser('statistics-order-type') && (
            <AsideMenuItem
              to='/statistics/order-type'
              title={intl.formatMessage({id: 'MENU.ORDER_TYPE'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-courier') && (
            <AsideMenuItem
              to='/statistics/couriers'
              title={intl.formatMessage({id: 'MENU.COURIERS'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-terminals') && (
            <AsideMenuItem
              to='/statistics/terminals'
              title={intl.formatMessage({id: 'MENU.BRANCHES'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-payment-type') && (
            <AsideMenuItem
              to='/statistics/payment-type'
              title={intl.formatMessage({id: 'MENU.PAYMENT_TYPE'})}
              hasBullet={true}
            />
          )}
          {/* {findRoleUser('statistics-location') && (
            <AsideMenuItem
              to='/statistics/location'
              title={intl.formatMessage({id: 'MENU.LOCATION'})}
              hasBullet={true}
            />
          )} */}
          {findRoleUser('statistics-products') && (
            <AsideMenuItem
              to='/statistics/products'
              title={intl.formatMessage({id: 'MENU.PRODUCTS'})}
              hasBullet={true}
            />
          )}
          {findRoleUser('statistics-system') && (
            <AsideMenuItem
              to='/statistics/system'
              title={intl.formatMessage({id: 'MENU.SYSTEM'})}
              hasBullet={true}
            />
          )}

          {findRoleUser('statistics-users') && (
            <AsideMenuItem
              to='/statistics/users'
              title={intl.formatMessage({id: 'COMMON.OPERATORS'})}
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      )}
      {findRoleUser('orders-view') && (
        <AsideMenuItem
          to='/orders'
          icon='/media/icons/duotune/files/fil001.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.ORDERS'})}
        />
      )}
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {findRoleUser('heat-map-view') && (
        <AsideMenuItem
          to='/heat-map'
          icon='/media/icons/duotune/maps/map002.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.HEAT_MAP'})}
        />
      )}

      {findRoleUser('couriers-map-view') && (
        <AsideMenuItem
          to='/couriers-map'
          icon='/media/icons/duotune/maps/map002.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.COURIERS_MAP'})}
        />
      )}
      {findRoleUser('couriers-map-view') && (
        <AsideMenuItem
          to='/couriers'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.COURIERS'})}
        />
      )}
      {findRoleUser('accounts-view') && (
        <AsideMenuItem
          to='/accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.CLIENTS'})}
        />
      )}
      {findRoleUser('feedbacks-view') && (
        <AsideMenuItem
          to='/feedbacks'
          icon='/media/icons/duotune/general/gen011.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.FEEDBACKS'})}
        />
      )}
      {findRoleUser('groups-show') && (
        <AsideMenuItem
          to='/groups'
          icon='/media/icons/duotune/general/gen022.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.MENU'})}
        />
      )}

      {findRoleUser('notifications-view') && (
        <AsideMenuItem
          to='/notifications'
          icon='/media/icons/duotune/communication/com004.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.NOTIFICATIONS'})}
        />
      )}
      {findRoleUser('slider-view') && (
        <AsideMenuItem
          to='/slider'
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.SLIDER'})}
        />
      )}
      {/* {findRoleUser('gallery-view') && (
        <AsideMenuItem
          to='/gallery'
          icon='/media/icons/duotune/general/gen006.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.GALLERY'})}
        />
      )} */}
      {/* {findRoleUser('news-view') && (
        <AsideMenuItem
          to='/news'
          icon='/media/icons/duotune/art/art008.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.NEWS'})}
        />
      )} */}
      {findRoleUser('pages-view') && (
        <AsideMenuItem
          to='/pages'
          icon='/media/icons/duotune/layouts/lay008.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.PAGES'})}
        />
      )}
      {/* {findRoleUser('restaurants-view') && (
        <AsideMenuItem
          to='/restaurants'
          icon='/media/icons/duotune/general/gen001.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.RESTAURANTS'})}
        />
      )} */}
      {findRoleUser('organizations-view') && (
        <AsideMenuItem
          to='/organizations'
          icon='/media/icons/duotune/ecommerce/ecm010.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.ORGANIZATION'})}
        />
      )}
      {findRoleUser('branches-view') && (
        <AsideMenuItem
          to='/branches'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.BRANCHES'})}
        />
      )}
      {findRoleUser('branches-view') && (
        <AsideMenuItem
          to='/payment-types'
          icon='/media/icons/duotune/ecommerce/ecm009.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.PAYMENT_TYPES'})}
        />
      )}
      {findRoleUser('users-view') && (
        <AsideMenuItem
          to='/users'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.USERS'})}
        />
      )}
      {findRoleUser('roles-view') && (
        <AsideMenuItem
          to='/roles'
          title={intl.formatMessage({id: 'MENU.ROLES'})}
          icon='/media/icons/duotune/communication/com014.svg'
          fontIcon='bi-layers'
        />
      )}
      {findRoleUser('settings-view') && (
        <AsideMenuItem
          to='/settings'
          icon='/media/icons/duotune/coding/cod001.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.SETTINGS'})}
        />
      )}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
    </>
  )
}
