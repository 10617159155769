import {useEffect, useRef, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'

import {SIPProvider} from 'react-sipjs'
import {SipPhoneModal} from '../../app/components/SipPhoneModal'
import {useIntl} from 'react-intl'

const CustomLayout = () => {
  const intl = useIntl()
  const sipPhoneModalRef = useRef()

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        {/* @ts-ignore: */}
        <SIPProvider
          options={{
            domain: process.env.REACT_APP_SIP_DOMAIN || '',
            webSocketServer: process.env.REACT_APP_SIP_WEBSOCKET || '',
          }}
        >
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />

              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                {/* <Toolbar /> */}
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </div>

          {/* begin:: Modals */}
          {/* <InviteUsers />
          <UpgradePlan /> */}

          {/* end:: Modals */}

          <ScrollTop />

          {/* SIP PHONE */}
          {/* <SipPhoneModal ref={sipPhoneModalRef} />
          <button
            className='btn btn-success position-fixed py-3 px-8'
            style={{
              right: 0,
              bottom: 0,
              borderRadius: 0,
            }}
            // @ts-ignore
            onClick={() => sipPhoneModalRef.current.showModal()}
          >
            {intl.formatMessage({id: 'COMMON.OPEN'})}
          </button> */}
          {/* END OF SIP PHONE */}
        </SIPProvider>
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {CustomLayout}
