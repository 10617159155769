import React, {useRef, useState, useImperativeHandle, forwardRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import * as Yup from 'yup'
import clsx from 'clsx'

import {useFormik} from 'formik'
import * as authHelper from '../../../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {swal} from '../../../../../../_metronic/helpers/swal'

import {swalDefaultConfig} from '../../../../../../_metronic/helpers/constants'

export const ModofierModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({
    // name: Yup.string()
    //   .min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1}))
    //   .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    // name_uz: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
    // name_en: Yup.string().min(1, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 1})),
  })

  const initialValues = {
    name_ru: '',
    name_uz: '',
    name_en: '',
  }

  if (!!props.choosenItem && !!props.choosenItem.modifier_id) {
    initialValues.name_ru = props.choosenItem.modifier.name_ru
    initialValues.name_uz = props.choosenItem.modifier.name_uz
    initialValues.name_en = props.choosenItem.modifier.name_en
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      let user = authHelper.getAuth()
      if (!user) return

      try {
        const res = await axios.put(
          `/modifiers/${props.choosenItem.modifier.id}`,
          {
            name_ru: values.name_ru,
            name_uz: values.name_uz,
            name_en: values.name_en,
            organization_id: props.org_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }

        setLoading(false)
        return
      }
      setShow(false)
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  return (
    <>
      <Modal
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        centered
      >
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}
            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_RU'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('name_ru')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.name_ru && formik.errors.name_ru},
                  {
                    'is-valid': formik.touched.name_ru && !formik.errors.name_ru,
                  }
                )}
                value={formik.values.name_ru}
              />
            </div>
            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_UZ'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('name_uz')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.name_uz && formik.errors.name_uz},
                  {
                    'is-valid': formik.touched.name_uz && !formik.errors.name_uz,
                  }
                )}
                value={formik.values.name_uz}
              />
            </div>
            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'ITEM.NAME_EN'})}</label>
              <input
                type='text'
                {...formik.getFieldProps('name_en')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.name_en && formik.errors.name_en},
                  {
                    'is-valid': formik.touched.name_en && !formik.errors.name_en,
                  }
                )}
                value={formik.values.name_en}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light btn-sm' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>
          <button
            type='button'
            className='btn btn-success btn-sm '
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={loading}
            data-kt-indicator={loading == true ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
})
