import React from 'react'
import axios from 'axios'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {useParams, useNavigate} from 'react-router-dom'

import {convertToPrice} from '../../../../_metronic/helpers/general'
import {swal} from '../../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import Loader from '../../../components/Loader'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import FooterCopyright from '../../../modules/FooterCopyright'

export function AccountViewTestIndex() {
  let navigate = useNavigate()
  const intl = useIntl()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  let {id} = useParams()
  const {classes} = useLayout()

  const [data, setData] = React.useState<any>({})
  const [statuses, setStatuses] = React.useState([])
  const [paymentTypes, setPaymentTypes] = React.useState([])
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  async function getData() {
    try {
      const res = await axios.get(`accounts/${id}`)
      setData(res.data || {})
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }
  React.useEffect(() => {
    if (!id) {
      navigate('/error/404')
      return
    }
    setIsLoading(true)

    getData()
    getStatuses()
    getPaymentTypes()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  async function getStatuses() {
    try {
      const res = await axios.get('/statuses/')

      setStatuses(res.data.data)
    } catch (err: any) {
      console.log('error', err)
    }
  }
  async function getPaymentTypes() {
    try {
      const res = await axios.get('/payment-types/')

      setPaymentTypes(res.data.data)
    } catch (err: any) {
      console.log('error', err)
    }
  }
  async function setBlocked() {
    // try {
    //   const res = await axios.put(`accounts/block/${id}?value=1`)
    // } catch (err: any) {
    //   console.log('error', err)
    //   setError(err)
    // }
    try {
      const res = await axios.patch(`accounts/block/`, {
        account_ids: [parseInt(id || '0', 10)],
        is_blocked: true,
      })
    } catch (err: any) {
      console.log('error', err)
      setError(err)
    }
  }
  async function setUnBlocked() {
    try {
      const res = await axios.patch(`accounts/block/`, {
        account_ids: [parseInt(id || '0', 10)],
        is_blocked: false,
      })
    } catch (err: any) {
      console.log('error', err)
      setError(err)
    }
  }

  const getOrdersTableContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className='c-pointer'
          onClick={() => {
            // navigate(`/orders/view/${value.id}`)
            navigate(`/orders/edit-order?orderId=${value.id}`)
            return
          }}
        >
          <td>{value.id}</td>
          <td>{value.number}</td>
          <td>{value.system}</td>
          <td>{value.name}</td>
          <td>{value.phone}</td>
          <td>{moment(value.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>{(value.terminal || {}).name_ru}</td>
          <td>
            {/* @ts-ignore: */}
            {(
              paymentTypes.find((x: any) => x.code == (value.payment_type || {}).code) || {
                name_ru: '',
              }
            ).name_ru || 'Не задано'}
          </td>
          <td>
            {convertToPrice(value.full_sum)} {intl.formatMessage({id: 'COMMON.SUM'})}
          </td>
          <td>
            {/* @ts-ignore: */}
            {(statuses.find((x: any) => x.id == value.status) || {}).description_ru ||
              intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
          </td>
        </tr>
      )
    }

    return content
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: `${data?.name}`,
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.CLIENT'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div
          className='h-30 card-body main-overflow-x'
          style={{height: windowSize.current[1] - 140}}
        >
          <div className='card card-stretch shadow mb-2'>
            <div className='card-header'>
              <h2 className='card-title'>
                {
                  // @ts-ignore:
                  data?.name
                }
              </h2>
              <div className='card-toolbar'>
                {
                  // @ts-ignore:
                  data?.is_blocked == 0 && (
                    <button
                      className='btn btn-primary btn-sm' // @ts-ignore
                      onClick={async () => (await setBlocked(), refreshData())}
                    >
                      {intl.formatMessage({id: 'COMMON.ADD_TO_BLACK_LIST'})}
                    </button>
                  )
                }
                {
                  // @ts-ignore:
                  data?.is_blocked == 1 && (
                    <button
                      className='btn btn-danger btn-sm' // @ts-ignore
                      onClick={async () => (await setUnBlocked(), refreshData())}
                    >
                      {intl.formatMessage({id: 'COMMON.DELETE_FROM_BLACK_LIST'})}
                    </button>
                  )
                }
              </div>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='table-responsive'>
                    <table className='table table-hover table-sm gy-2 gs-7'>
                      <tbody>
                        <tr>
                          <th className=' fs-6 text-gray-800'>ID</th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.id
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.PHONE'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.phone || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.BIRTH_DATE'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.birthday
                                ? // @ts-ignore:
                                  moment(data?.birthday).format('DD.MM.YYYY')
                                : intl.formatMessage({id: 'COMMON.NOT_DEFINED'})
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.SYSTEM'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.system || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.REG_DATE'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.date
                                ? // @ts-ignore:
                                  moment(data?.date).format('DD.MM.YYYY')
                                : intl.formatMessage({id: 'COMMON.NOT_DEFINED'})
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.LAST_VISIT'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.last_visit
                                ? // @ts-ignore:
                                  moment(data?.last_visit).format('DD.MM.YYYY')
                                : intl.formatMessage({id: 'COMMON.NOT_DEFINED'})
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='table-responsive'>
                    <table className='table table-hover table-sm gy-2 gs-7'>
                      <tbody>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.ORDERS_COUNT'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.order_count
                                ? // @ts-ignore:
                                  data?.order_count + ' ' + intl.formatMessage({id: 'COMMON.PCS'})
                                : 0
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.UNACCEPTED_ORDERS'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.order_un_accepted_count
                                ? // @ts-ignore:
                                  data?.order_un_accepted_count +
                                  ' ' +
                                  intl.formatMessage({id: 'COMMON.PCS'})
                                : 0
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.PROCESSED_ORDERS'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.order_processed_count
                                ? // @ts-ignore:
                                  data?.order_processed_count +
                                  ' ' +
                                  intl.formatMessage({id: 'COMMON.PCS'})
                                : 0
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.CANCELED_ORDERS'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.order_canceled_count
                                ? // @ts-ignore:
                                  data?.order_canceled_count +
                                  ' ' +
                                  intl.formatMessage({id: 'COMMON.PCS'})
                                : 0
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.EXPENSIVE_ORDER'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.order_max
                                ? // @ts-ignore:
                                  convertToPrice(data?.order_max) +
                                  ' ' +
                                  intl.formatMessage({id: 'COMMON.SUM'})
                                : 0
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.CHEAP_ORDER'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.order_min
                                ? // @ts-ignore:
                                  convertToPrice(data?.order_min) +
                                  ' ' +
                                  intl.formatMessage({id: 'COMMON.SUM'})
                                : 0
                            }
                          </td>
                        </tr>
                        <tr>
                          <th className=' fs-6 text-gray-800'>
                            {intl.formatMessage({id: 'COMMON.AVG_CHECK'})}
                          </th>
                          <td>
                            {
                              // @ts-ignore:
                              data?.order_avg
                                ? // @ts-ignore:
                                  convertToPrice(data?.order_avg) +
                                  ' ' +
                                  intl.formatMessage({id: 'COMMON.SUM'})
                                : 0
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-stretch shadow mb-2'>
            <div className='card-header'>
              <h2 className='card-title'>{intl.formatMessage({id: 'MENU.ORDERS'})}</h2>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-1 gs-1'>
                  <thead>
                    <tr className=' bg-light'>
                      <th>ID</th>
                      <th className='min-w-10px'>{intl.formatMessage({id: 'COMMON.CHEQUE'})}</th>
                      <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.SYSTEM'})}</th>
                      <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.CLIENT'})}</th>
                      <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.PHONE'})}</th>
                      <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                      <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.BRANCH'})}</th>
                      <th className='min-w-50px'>
                        {intl.formatMessage({id: 'COMMON.PAYMENT_TYPE'})}
                      </th>
                      <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.TOTAL'})}</th>
                      <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                    </tr>
                  </thead>
                  {/* @ts-ignore: */}
                  <tbody>{getOrdersTableContent(data.orders || [])}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
