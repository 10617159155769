// @ts-nocheck
import React, { useEffect, useState } from 'react'
import axios from 'axios'
// @ts-ignore
import 'react-datepicker/dist/react-datepicker.css'

import { useIntl } from 'react-intl'

import { PageTitle } from '../../../_metronic/layout/core'
import { useParams } from 'react-router-dom'

export function OrdersView() {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { id } = useParams()

  const [orderItems, setOrderItems] = useState([])
  const [order, setOrder] = useState({})

  const getOrder = async () => {
    const order = await axios.get(`/orders/${id}`).catch(err => console.log(err))
    setOrder(order.data)
    setOrderItems(order.data.items || [])
  }

  useEffect(() => {
    getOrder()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{t('MENU.STATISTICS')}</PageTitle>
      {order && <div className="card card-stretch shadow mb-5 p-3">
        <div className="d-flex align-items-center p-3 justify-content-between">
          <div>
            <h1>Order <span>#{order.id}</span></h1>
            <div>Status: {order.status}</div>
          </div>
        </div>
        <div className='row p-3'>
          <div className="col-md-6">
            <ul className='list-unstyled'>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Branch</div>
                <div>{order.transaction_id}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Client</div>
                <div>{order.name}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Nomer</div>
                <div>{order.phone}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>System</div>
                <div>{order.system}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Data</div>
                <div>{order.time}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Address</div>
                <div>{order.commentary}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Nearby</div>
                <div>{order.comment}</div>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <ul className='list-unstyled'>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Pickup</div>
                <div>{order.is_self_service}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Payment type</div>
                <div>{order.payment_type}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Delivery Distance</div>
                <div>{order.distance}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Payed</div>
                <div>{order.is_paid}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Edited</div>
                <div>{order.edited}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Edited by</div>
                <div>{order.edited_name}</div>
              </li>
              <li className='d-flex border-bottom py-2'>
                <div className='w-md-25'>Comment</div>
                <div>{order.comment}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>}
      <div className="card card-stretch shadow mb-5 p-3">
        <div className="d-flex align-items-center p-3 justify-content-between">
          <div>
            <h2>Products</h2>
          </div>
        </div>
        <div className='row p-3'>
          <table className="table table-striped border-top border-bottom gy-7 gs-7">
            <thead>
              <tr className=" fs-6 text-gray-800 border-bottom border-gray-200">
                <th>#</th>
                <th>Name</th>
                <th>Options</th>
                <th>Comments</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orderItems.length > 0 && orderItems.map((x, idx) => {
                return (
                  <tr key={'order-item-index-' + idx}>
                    <td>{idx + 1}</td>
                    <td>{x.item_name}</td>
                    <td>OPTIONS</td>
                    <td>{x.comment}</td>
                    <td>{x.amount}</td>
                    <td>{x.price} sum</td>
                    <td>{x.price * x.amount} sum</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
