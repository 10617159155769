import React, {FC, useRef} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import {useSearchParams} from 'react-router-dom'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import axios from 'axios'
import {daysOfWeek, monthNames, swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {convertToPrice} from '../../../_metronic/helpers/general'
import {swal} from '../../../_metronic/helpers/swal'
// @ts-ignore
import * as XLSX from 'xlsx'
import FooterCopyright from '../../modules/FooterCopyright'
import Loader from '../../components/Loader'

const StatisticsXyzIndex: FC = () => {
  const intl = useIntl()
  const {classes} = useLayout()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let dateFrom = searchParams.get('dateFrom') || moment().format('YYYY-MM-DD')
  let dateTo = searchParams.get('dateTo') || moment().format('YYYY-MM-DD')

  const [data, setData] = React.useState<any>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  const getQueryObject = (): any => {
    let obj: {
      dateFrom?: string
      dateTo?: string
    } = {}

    if (!!dateFrom) obj.dateFrom = dateFrom
    if (!!dateTo) obj.dateTo = dateTo

    return obj
  }

  const buildQueryParams = () => {
    let query = `?`

    if (!!dateFrom) query += `start_date=${dateFrom}`
    if (!!dateTo) query += `&end_date=${dateTo}`

    return query
  }

  async function getData() {
    try {
      const res = await axios.get(`/stats/products/xyz/${buildQueryParams()}`)
      setData((res.data || {}).products || [])

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      }
      if (err.response.status == 500) {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  React.useEffect(() => {
    setData([])
    getData()
  }, [searchParams])

  const handleDateRangePickerCallback = (start: any, end: any, label: any) => {
    setSearchParams({
      ...getQueryObject(),
      dateFrom: start.format('YYYY-MM-DD'),
      dateTo: end.format('YYYY-MM-DD'),
    })
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.STATS'}),
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'COMMON.CURRENT'}),
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []
    let content = []
    for (const [i, value] of arr.entries()) {
      content.push(
        <>
          <tr
            key={'list-' + value.services?.id}
            className={`bg-opacity-50 ${
              value.stability_group == 'X'
                ? 'bg-success'
                : value.stability_group == 'Y'
                ? 'bg-warning'
                : 'bg-danger'
            }`}
          >
            <td style={{width: 40}}>{i + 1}</td>
            <td className='text-start'>{value.name_ru}</td>
            <td className='text-center'>
              {convertToPrice(Math.round(value.total_sales || 0))}{' '}
              {intl.formatMessage({id: 'COMMON.SUM'})}
            </td>
            <td className='text-center'>
              {convertToPrice(Math.round(value.avg_monthly_sales || 0))}{' '}
              {intl.formatMessage({id: 'COMMON.SUM'})}
            </td>
            <td className='text-center'>{convertToPrice(parseFloat(value.stdo).toFixed(2))}</td>
            <td className='text-center'>{convertToPrice(parseFloat(value.kv).toFixed(2))}</td>
            <td className='text-center'>{value.stability_group}</td>
          </tr>
        </>
      )
    }

    return content
  }

  // const exportToExcel = () => {
  //   let newData: any = []
  //   data.map((d: any) => {
  //     newData.push({
  //       Тип:
  //         d.type == 'completed'
  //           ? intl.formatMessage({id: 'COMMON.COMPLETED'})
  //           : intl.formatMessage({id: 'COMMON.UNCOMPLETED'}),
  //       Количество: d.count,
  //       Сред: d.avg,
  //       Итого: d.total,
  //     })
  //   })
  //   newData.push({
  //     Итого: convertToPrice(total),
  //   })

  //   const fileType =
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //   const fileExtension = '.xlsx'
  //   const fileName = 'Выручка'

  //   const ws = XLSX.utils.json_to_sheet(newData)
  //   const wb = {Sheets: {data: ws}, SheetNames: ['data']}
  //   const excelBuffer = XLSX.write(wb, {
  //     bookType: 'xlsx',
  //     type: 'array',
  //   })
  //   const dataBlob = new Blob([excelBuffer], {type: fileType})
  //   const url = URL.createObjectURL(dataBlob)
  //   const link = document.createElement('a')
  //   link.href = url
  //   link.download = fileName + fileExtension
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }

  const getDataByCategory = (arr: any, category: any = null) => {
    if (!category) {
      return arr.sort((a: any, b: any) => b.total_sales - a.total_sales)
    }

    let newArr = arr.filter((x: any) => x.stability_group == category)
    return newArr.sort((a: any, b: any) => b.total_sales - a.total_sales)
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{intl.formatMessage({id: 'MENU.XYZ'})}</PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              <div className='input-group-sm cs-daterangepicker-wrapper'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(dateFrom, 'YYYY-MM-DD'),
                    endDate: moment(dateTo, 'YYYY-MM-DD'),
                    ranges: {
                      [intl.formatMessage({id: 'COMMON.TODAY'})]: [moment(), moment()],
                      [intl.formatMessage({id: 'COMMON.YESTERDAY'})]: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_7_DAYS'})]: [
                        moment().subtract(6, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_30_DAYS'})]: [
                        moment().subtract(29, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.THIS_MONTH'})]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_MONTH'})]: [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    },
                    locale: {
                      format: 'DD/MM/YYYY',
                      separator: ' - ',
                      applyLabel: 'Подтвердить',
                      cancelLabel: 'Отменить',
                      fromLabel: 'From',
                      toLabel: 'To',
                      customRangeLabel: 'Пользовательский',
                      daysOfWeek: daysOfWeek,
                      monthNames: monthNames,
                      firstDay: 1,
                    },
                  }}
                  onCallback={handleDateRangePickerCallback}
                >
                  <input type='text' className='form-control' />
                </DateRangePicker>
              </div>
            </div>
            {/* <div className='card-toolbar'>
              <div className='ms-3'>
                <button
                  className='btn btn-success btn-sm'
                  data-bs-toggle='modal'
                  onClick={exportToExcel}
                >
                  {intl.formatMessage({id: 'COMMON.DOWNLOAD'})}
                </button>
              </div>
            </div> */}
          </div>
        </div>

        <div
          className='card card-stretch shadow mb-2 flex-column-fluid main-overflow-x'
          style={{height: windowSize.current[1] - 320}}
        >
          <div className='card-body'>
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
              <li className='nav-item'>
                <a
                  className='nav-link text-dark active'
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_all'
                >
                  {intl.formatMessage({id: 'MENU.COMMON'})}
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link text-dark' data-bs-toggle='tab' href='#kt_tab_pane_x'>
                  X
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link text-dark' data-bs-toggle='tab' href='#kt_tab_pane_y'>
                  Y
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link text-dark' data-bs-toggle='tab' href='#kt_tab_pane_z'>
                  Z
                </a>
              </li>
            </ul>
            <div className='tab-content' id='abcTabContent'>
              <div className='tab-pane fade active show' id='kt_tab_pane_all' role='tabpanel'>
                <div className='table-responsive'>
                  <table className='table border table-rounded table-sm table-row-dashed table-row-gray-300 gy-3 gs-2'>
                    <thead>
                      <tr className=' bg-light'>
                        <th style={{width: 40}}>#</th>
                        <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                        <th className='text-center'>{intl.formatMessage({id: 'COMMON.TOTAL'})}</th>
                        <th className='text-center'>Ср</th>
                        <th className='text-center'>СТДО</th>
                        <th className='text-center'>КВ</th>
                        <th className='text-center'>{intl.formatMessage({id: 'COMMON.GROUP'})}</th>
                      </tr>
                    </thead>
                    <tbody>{getDataContent(getDataByCategory(data))}</tbody>
                  </table>
                </div>
              </div>
              <div className='tab-pane fade' id='kt_tab_pane_x' role='tabpanel'>
                <div className='table-responsive'>
                  <table className='table border table-rounded table-sm table-row-dashed table-row-gray-300 gy-3 gs-2'>
                    <thead>
                      <tr className=' bg-light'>
                        <th style={{width: 40}}>#</th>
                        <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                        <th className='text-center'>{intl.formatMessage({id: 'COMMON.TOTAL'})}</th>
                        <th className='text-center'>Ср</th>
                        <th className='text-center'>СТДО</th>
                        <th className='text-center'>КВ</th>
                        <th className='text-center'>{intl.formatMessage({id: 'COMMON.GROUP'})}</th>
                      </tr>
                    </thead>
                    <tbody>{getDataContent(getDataByCategory(data, 'X'))}</tbody>
                  </table>
                </div>
              </div>
              <div className='tab-pane fade' id='kt_tab_pane_y' role='tabpanel'>
                <div className='table-responsive'>
                  <table className='table border table-rounded table-sm table-row-dashed table-row-gray-300 gy-3 gs-2'>
                    <thead>
                      <tr className=' bg-light'>
                        <th style={{width: 40}}>#</th>
                        <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                        <th className='text-center'>{intl.formatMessage({id: 'COMMON.TOTAL'})}</th>
                        <th className='text-center'>Ср</th>
                        <th className='text-center'>СТДО</th>
                        <th className='text-center'>КВ</th>
                        <th className='text-center'>{intl.formatMessage({id: 'COMMON.GROUP'})}</th>
                      </tr>
                    </thead>
                    <tbody>{getDataContent(getDataByCategory(data, 'Y'))}</tbody>
                  </table>
                </div>
              </div>
              <div className='tab-pane fade' id='kt_tab_pane_z' role='tabpanel'>
                <div className='table-responsive'>
                  <table className='table border table-rounded table-sm table-row-dashed table-row-gray-300 gy-3 gs-2'>
                    <thead>
                      <tr className=' bg-light'>
                        <th style={{width: 40}}>#</th>
                        <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                        <th className='text-center'>{intl.formatMessage({id: 'COMMON.TOTAL'})}</th>
                        <th className='text-center'>Ср</th>
                        <th className='text-center'>СТДО</th>
                        <th className='text-center'>КВ</th>
                        <th className='text-center'>{intl.formatMessage({id: 'COMMON.GROUP'})}</th>
                      </tr>
                    </thead>
                    <tbody>{getDataContent(getDataByCategory(data, 'Z'))}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}

export {StatisticsXyzIndex}
