import React from 'react'
import axios from 'axios'
import {useIntl} from 'react-intl'

import {useNavigate} from 'react-router-dom'
import FooterCopyright from '../../modules/FooterCopyright'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import {swal} from '../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import Loader from '../../components/Loader'
import {useRoleContext} from '../../context/getAccessRoleUser'
import moment from 'moment'

export function GroupsIndex() {
  const intl = useIntl()
  const {findRoleUser} = useRoleContext()

  let navigate = useNavigate()
  const {classes} = useLayout()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  const [data, setData] = React.useState([])
  const [choosenItem, setChoosenItem] = React.useState(null)
  const [syncBtnLoading, setSyncBtnLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  async function getData() {
    try {
      const res = await axios.get('/organization/all/')
      setData(res.data.organizations || [])
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }
  React.useEffect(() => {
    setIsLoading(true)
    getData()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id == choosenItem.id) || null)
    }
  }, [data])

  const syncIIKOhandler = async () => {
    setSyncBtnLoading(true)

    try {
      const res = await axios.get('/iiko/sync')
    } catch (err: any) {
      console.log('error', err)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }

    await refreshData()

    setSyncBtnLoading(false)
  }

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className='c-pointer'
          onClick={() => {
            navigate(`/groups/menu/${value.id}`)
          }}
        >
          <td style={{width: 40}}>{i + 1}</td>
          <td>{value.name}</td>
          <td>{moment(value.synced_at).format('DD/MM/YYYY HH:mm')}</td>
        </tr>
      )
    }

    return content
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{intl.formatMessage({id: 'MENU.MENU'})}</PageTitle>
      <div className='card card-stretch shadow mb-2'>
        <div className='card-header'>
          <div className='d-flex align-items-center justify-content-center'>
            {findRoleUser('groups-add') && (
              <button
                className='btn btn-success btn-sm' // @ts-ignore
                onClick={() => syncIIKOhandler()}
                disabled={!!syncBtnLoading}
              >
                {intl.formatMessage({id: 'COMMON.SYNC_IIKO'})}

                {syncBtnLoading && (
                  <span className='indicator-progress' style={{display: 'inline-block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}
          </div>

          {/* <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-light-primary me-3'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <i className='fa-solid fa-filter'></i>
              Filter
            </button>
          </div> */}
        </div>
      </div>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          {/* <div className='card-header'>
            <h2 className='card-title'>{intl.formatMessage({id: 'MENU.MENU'})}</h2>
            <div className='card-toolbar'>
              <button
                className='btn btn-primary btn-sm' // @ts-ignore
                onClick={() => syncIIKOhandler()}
                disabled={!!syncBtnLoading}
              >
                {intl.formatMessage({id: 'COMMON.SYNC_IIKO'})}

                {syncBtnLoading && (
                  <span className='indicator-progress' style={{display: 'inline-block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div> */}
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-1 gs-1'>
                <thead>
                  <tr className=' bg-light'>
                    <th>#</th>
                    <th>{intl.formatMessage({id: 'MENU.ORGANIZATION'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.LAST_REVISION'})}</th>
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
